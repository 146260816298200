import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store/store.js'
import axiosHelper from "@/util/axiosHelper.js";
import axiosJsonHelper from "@/util/axiosJsonHelper.js";
import axiosFileHelper from "@/util/axiosFileHelper.js";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import i18n from "@/util/i18n";

import PrimeVue from 'primevue/config';
// PrimeVue 스타일시트
import 'primevue/resources/themes/aura-light-green/theme.css'

//디렉티브 파일
import { clickOutside } from '@/directives/clickOutside';


const app = createApp(App);
app.config.globalProperties.$axios = axiosHelper
app.config.globalProperties.$axiosJson = axiosJsonHelper
app.config.globalProperties.$axiosFile = axiosFileHelper

app.use(VueSweetalert2);

app.directive('click-outside', clickOutside);

app.use(router).use(store).use(i18n).use(PrimeVue).mount('#app')

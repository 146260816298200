<template>
<li class="tw-comment-list" v-for="(cmmntCmnt, index) in cmntLst" :key="index" >
  <cmnt-item :cmmntCmnt="cmmntCmnt" :sUserNum="sUser.userNum" :isReply="false" />
  <ul class="tw-reply-list">
    <li class="tw-comment-list reply">
      <div class="tw-comment-list-left" v-for="(childCmnt, index) in cmmntCmnt.childCmntView" :key="index">
        <cmnt-item :cmmntCmnt="childCmnt" :sUserNum="sUser.userNum" :isReply="true" />
      </div>
    </li>
  </ul>
</li>
</template>


<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue'
import { createLogger, useStore } from "vuex";

import { useNavigation } from '@/page/main/js/useNavigation';
const { navigate } = useNavigation();

import cmntItem from '@/page/main/views/cmmnt/comm/cmntItem.vue'

const store = useStore();

const sUser = computed(() => store.state.user.user);
const cmntLst = computed(() => store.state.cmmnt.cmnt.lst);

const activeModalId = ref(null);

// 외부 클릭 이벤트를 처리하는 함수
const onClickOutside = (event) => {
    const modalElements = document.querySelectorAll('.tw-pop-mini, .tw-comment-icon');

    // 클릭된 요소가 모달 내부가 아닌 경우 모달 닫기
    for (const modalElement of modalElements) {
        if (!modalElement.contains(event.target)) {
            activeModalId.value = null;
            break;
        }
    }
}

// 외부 클릭 이벤트 처리
onMounted(() => window.addEventListener('click', onClickOutside));
onBeforeUnmount(() => window.removeEventListener('click', onClickOutside));

</script>
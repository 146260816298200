<template>

          <div :id="tab.id" class="tab-content" :class="{ current: currentTab === tab.id }">
            <div class="tw-commu-best-box">
              <h2 class="tw-label-default bold">{{ $t('user.main.CMMNT.MAIN-BEST.TITLE.' + tab.code) }} </h2>
              <div class="tw-main-tab-list">
                <nav>
                  <ul>
                    <li v-for="(cmmnt, index) in cmmntLst" :key="index" @click="goCmmnt('cmmntDtl', cmmnt.postNum)">
                      <div class="rank">{{ index + 1 }} </div>
                      <div class="rank-img">
                        <a><img :src="cmmnt.thmbFullPath ? cmmnt.thmbFullPath : '/tripwith/assets/images/img-sample(1).png'" alt="랭킹 이미지"></a>
                      </div>
                      <div class="rank-wrap">
                        <div class="rank-wrap-detail">
                          <a>
                            <p class="detail-tit">{{ cmmnt.title }}</p>
                            <p class="detail-body">{{ cmmnt.cntn }}</p>
                          </a>
                          <div class="info-txt-bt left">
                                <div class="info-bt-like icon" :class="cmmnt.likeCnt > 0 ? 'on' : ''" tabindex="0">
                                  <span class="tw-like-icon">좋아요 아이콘</span>
                                  <span class="tw-like-txt">{{ cmmnt.likeCnt > 0 ? cmmnt.likeCnt : '좋아요'}}</span>
                                </div>
                            <div class="info-bt-comm icon" :class="cmmnt.cmntCnt > 0 ? 'on' : ''" tabindex="0">
                              <span class="tw-comm-icon">댓글 아이콘</span>
                              <span class="tw-comm-txt">{{ cmmnt.cmntCnt > 0 ? cmmnt.cmntCnt : '댓글달기'}}</span>
                            </div>
                            <div class="info-bt-view icon" :class="cmmnt.viewCnt > 0 ? 'on' : ''" tabindex="0">
                              <span class="tw-view-icon">조회수 아이콘</span>
                              <span class="tw-view-txt">{{ cmmnt.viewCnt > 0 ? cmmnt.viewCnt : '조회수'}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <template v-if="cmmntLst && cmmntLst.length == 0">
                      <li>                      
                        <div class="rank"> </div>
                        <div class="rank-img loading">
                          <a></a>
                        </div>
                        <div class="rank-wrap">
                          <div class="rank-wrap-detail loading">
                            <a >
                              <p class="detail-tit"></p>
                              <p class="detail-body"></p>
                            </a>
                            <div class="info-txt-bt left">
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="rank"> </div>
                        <div class="rank-img loading">
                          <a></a>
                        </div>
                        <div class="rank-wrap">
                          <div class="rank-wrap-detail loading">
                            <a >
                              <p class="detail-tit"></p>
                              <p class="detail-body"></p>
                            </a>
                            <div class="info-txt-bt left">
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="rank"> </div>
                        <div class="rank-img loading">
                          <a></a>
                        </div>
                        <div class="rank-wrap">
                          <div class="rank-wrap-detail loading">
                            <a >
                              <p class="detail-tit"></p>
                              <p class="detail-body"></p>
                            </a>
                            <div class="info-txt-bt left">
                            </div>
                          </div>
                        </div>
                      </li>
                    </template>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
  
</template>

<script setup>
import { ref, reactive, onMounted, getCurrentInstance, computed, defineProps } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { createLogger, useStore } from "vuex";


//글로벌 프로퍼티 설정 부분
import { useGlobalProperties } from '@/page/main/js/composables/useGlobalProperties'; 
const { $axios } = useGlobalProperties();

import { useNavigation } from '@/page/main/js/useNavigation';
const { navigate, goBack } = useNavigation();

import { useComm } from '@/page/main/js/comm';
const { toggleCommLayer, toggleCommAlert, loginChk } = useComm();

const props = defineProps({
  tab: {
    type: Object,
    required: true
  },
})

const store = useStore();

const currentTab = computed(() => store.state.cmmnt.main.currentTab);

const pageObj = reactive({totalCnt : 0 //최대 리스트 개수
                    ,totalPage : 3 // 최대 페이지 개수 
                    ,pageNum : 0 //현재 페이지 번호(실제 페이지 넘버 -1)
                    ,pageSize : 3 //보여줄 페이징의 최대 개수
                    ,pageBlockNum : 3 //한 페이지 내에 보여줄 리스트 개수
                    })

const cmmnt = ref({});
const cmmntLst = ref([]);


const goGetLstCmmnt = async (pCmmnt) => {
  let cmmntLst;
    await $axios({
        method: 'GET',
        params: pCmmnt,
        url: '/tripwith/post/user',
    }).then((data) => {
        const resultObj = data.resultObj.content;
        if (resultObj) {
            cmmntLst = resultObj;
        }
    }).catch((error) => {
        toggleCommAlert("COMM", "FAILED");
    })
    return cmmntLst;
};

onMounted( async () => {
  if(props.tab.needLogin){
    const sUser = computed(() => store.state.user.user);
    if(!sUser.value.userNum){
      return false;
    }
  }

  cmmnt.value.pageNum = pageObj.pageNum;
  cmmnt.value.pageSize = pageObj.pageSize;
  cmmnt.value.boardNum = '3';
  cmmnt.value.orderCtgryCode = props.tab.code;
  cmmntLst.value = await goGetLstCmmnt(cmmnt.value);
})


const goCmmnt = (pName, pPostNum) => {
  navigate({value:pName, params: {postNum:pPostNum} } );
}

</script>
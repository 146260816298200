<template>

  <main>
    <div class="tw-wrap h-none">

      <header class="tw-top icon">
        <h1 class="tw-label-top">Community</h1>
        <div class="tw-top-right">
          <a class="tw-right-icon" @click="navigate({value:'cmmntFilter'})">검색 아이콘</a>
          <!-- <a href="../3step/PB_NC_002.html" class="tw-alarm-icon">알림 아이콘</a> -->
        </div>
      </header>

      <div class="tw-body p-0">
        <main-filter-swiper />
        <!-- <div class="tw-body-category">
          <div class="swiper mySwiper tw-swiper-key swiper-initialized swiper-horizontal swiper-backface-hidden">
            <div class="swiper-wrapper" id="swiper-wrapper-1fa77efdd1047f4f5" aria-live="polite">
                <div class="swiper-slide tw-swiper-txt tw-swiper-active swiper-slide-active" role="group" aria-label="1 / 10" tabindex="0">전체</div>
                <div class="swiper-slide tw-swiper-txt swiper-slide-next" role="group" aria-label="2 / 10">인천공항</div>
                <div class="swiper-slide tw-swiper-txt" role="group" aria-label="3 / 10">숙박세일페스타</div>
                <div class="swiper-slide tw-swiper-txt" role="group" aria-label="4 / 10">항공권할인</div>
                <div class="swiper-slide tw-swiper-txt" role="group" aria-label="5 / 10">서울맛집</div>
                <div class="swiper-slide tw-swiper-txt" role="group" aria-label="6 / 10">제주도</div>
                <div class="swiper-slide tw-swiper-txt" role="group" aria-label="7 / 10">인천공항</div>
                <div class="swiper-slide tw-swiper-txt" role="group" aria-label="8 / 10">숙박세일페스타</div>
                <div class="swiper-slide tw-swiper-txt" role="group" aria-label="9 / 10">항공권할인</div>
                <div class="swiper-slide tw-swiper-txt" role="group" aria-label="10 / 10">서울맛집</div>
            </div>
            <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
        </div>
        </div> -->
        <div class="tw-main-tab p-0">
          <top-3 />
          
          <div class="tw-commu-tab-list">
            <ul>
              <li v-for="(cmmnt, index) in cmmntLst" :key="index">
                <div class="tw-info-detail">
                  <div class="tw-info-detail-top">
                    <div class="detail-top-info-wrap">
                      <div class="detail-top-info-img">
                        <img :src="cmmnt.fullPath" alt="닉네임 프로필 이미지">
                      </div>
                      <h2 class="detail-top-info-id">{{ cmmnt.nick }}
                        <!-- <span class="id-badge">
                          <img src="/tripwith/assets/images/img-level-total-2.svg" alt="뱃지 이미지">
                        </span> -->
                      </h2>
                      <div class="detail-top-info-date">{{ convertTimestampToFormattedDate(cmmnt.regDt) }}</div>
                    </div>
                    <div class="detail-top-icon-wrap">
                      <span :class="['tw-bookmark-icon', { on: cmmnt.dibsedByUser }]" @click="togglePostDibs(cmmnt)">북마크 아이콘</span>
                      
                      <template v-if="cmmnt.userNum == sUser.userNum">
                        <span class="tw-more-icon" aria-haspopup="true" tabindex="0" @click="clickMoreIcon(cmmnt.postNum)">더보기 아이콘</span>
                        <div class="tw-pop-mini"  role="dialog" aria-modal="true" v-show="isActiveModal(cmmnt.postNum).value" v-click-outside="onClickOutside">
                          <ul>
                            <li><a @click="goCmmnt('cmmntWrite', cmmnt.postNum)">{{$t('user.main.COMM.UPD')}}</a></li>
                            <li><a @click="delCmmntConfirm(cmmnt)" >{{$t('user.main.COMM.DEL')}}</a></li>
                          </ul>
                        </div>
                      </template>
                    </div>
                  </div>
                  <div class="tw-info-detail-body">

                    <div class="detail-body-txt">

                      <h2>{{ cmmnt.title }}</h2>
                      {{ cmmnt.cntn }}
          
                    </div>

                    <div class="detail-body-img-wrap">
                      <ul>
                        <li v-for="(file, index) in cmmnt.postFileModelLst" :key="index">
                          <div> <img :src="file.fullPath" alt="첨부 이미지"> </div>
                        </li>
                      </ul>
                    </div>

                  </div>
                  <div class="tw-info-detail-btn">
                    <div
                      :class="['info-bt-like icon', { on: cmmnt.likedByUser }]"
                      tabindex="0"
                      @click="togglePostLike(cmmnt.postNum)"
                    >
                      <span class="tw-like-icon">좋아요 아이콘</span>
                      <span class="tw-like-txt">{{ cmmnt.likeCnt > 0 ? cmmnt.likeCnt : $t('user.main.CMMNT.MAIN.LIKE')}}</span>
                    </div>
                    <div class="info-bt-comm icon" :class="cmmnt.cmntCnt > 0 ? 'on' : ''" tabindex="0" @click="goCmmnt('cmmntDtl', cmmnt.postNum)">
                      <span class="tw-comm-icon">댓글 아이콘</span>
                      <span class="tw-comm-txt">{{ cmmnt.cmntCnt > 0 ? cmmnt.cmntCnt : $t('user.main.CMMNT.MAIN.CMNT')}}</span>
                    </div>
                    <div class="info-bt-share icon" tabindex="0">
                      <span class="tw-share-icon">공유하기 아이콘</span>
                      <span class="tw-share-txt">{{$t('user.main.CMMNT.MAIN.SHARE')}}</span>
                    </div>
                  </div>
                </div>
              </li>
              
            </ul>
          </div>

        </div>
      </div>

    </div>
  </main>
</template>

<script setup>

import { ref, onMounted, onBeforeMount, getCurrentInstance, computed, reactive } from 'vue'
import { useStore } from "vuex";
import { createI18n } from 'vue-i18n'
import { useI18n } from 'vue-i18n';
import { debounce, throttle } from 'lodash'

//공통 js import
import { useNavigation } from '@/page/main/js/useNavigation';
const { navigate } = useNavigation();

import { useComm } from '@/page/main/js/comm';
const { toggleCommLayer, toggleCommAlert, loginChk } = useComm();

import top3 from '@/page/main/views/cmmnt/comm/top3.vue'
import mainFilterSwiper from '@/page/main/views/cmmnt/comm/mainFilterSwiper.vue';

//글로벌 프로퍼티 설정 부분
import { useGlobalProperties } from '@/page/main/js/composables/useGlobalProperties'; 
const { $axios } = useGlobalProperties();

const messages = loadLocaleMessages()

function loadLocaleMessages() {
  // json 파일을 읽어들이기 위한 function
  const requireLang = require.context('@/lang', true, /\.json$/);
  const messages = {};
  // json file read
  for (const file of requireLang.keys()) {
    if (file === './index.js') continue; // index.js 파일 제외
    const path = file.replace(/(\.\/|\.json$)/g, '').split('/');
    path.reduce((o, s, i) => {
      if (o[s]) return o[s];
      o[s] = i + 1 === path.length ? requireLang(file) : {};
      return o[s];
    }, messages);
  }
  return messages;
}


//vuex 설정
const store = useStore();

//메시지
//$t로 공통 언어에 접근하고 그냥 t로 각 페이지별 언어에 접근한다. 

const pageObj = reactive({totalCnt : 0 //최대 리스트 개수
                    ,totalPage : 100 // 최대 페이지 개수 
                    ,pageNum : 0 //현재 페이지 번호(실제 페이지 넘버 -1)
                    ,pageSize : 10 //보여줄 페이징의 최대 개수
                    ,pageBlockNum : 8 //한 페이지 내에 보여줄 리스트 개수
                    })

const cmmnt = ref({});
const cmmntLst = ref({});

const sUser = computed(() => store.state.user.user);

const goGetLstCmmnt = async (pCmmnt) => {
  let cmmntLst;
    await $axios({
        method: 'GET',
        params: pCmmnt,
        url: '/tripwith/post/user',
    }).then((data) => {
        const resultObj = data.resultObj.content;
        if (resultObj) {
            cmmntLst = resultObj;
            pageObj.totalPage = data.resultObj.totalPages;
            cmmnt.value.pageNum++;
        }
    }).catch((error) => {
        toggleCommAlert("COMM", "FAILED");
    })
    return cmmntLst;
};


onMounted( async () => {
  cmmnt.value.pageNum = pageObj.pageNum;
  cmmnt.value.pageSize = pageObj.pageSize;
  cmmnt.value.boardNum = '3';
  cmmnt.value.orderCtgryCode = '003';
  cmmntLst.value = await goGetLstCmmnt(cmmnt.value);
})


const clickMoreIcon = (pPostNum) =>{
  event.stopPropagation();
  store.dispatch('cmmnt/setActivePostNum', pPostNum);
}

const isActiveModal = (pPostNum) => {
  return computed(() => store.state.cmmnt.cmmnt.activePostNum === pPostNum)
}

// 외부 클릭 이벤x트를 처리하는 함수
const onClickOutside = (event) => {
  store.dispatch('cmmnt/clearActivePostNum');
}

const goCmmnt = (pName, pPostNum) => {
  navigate({value:pName, params: {postNum:pPostNum} } );
}

const delCmmntConfirm = (pCmmnt) => {
  const onConfirm = () => {
    delCmmnt(pCmmnt); 
  };

  //confirm open
  toggleCommAlert("CMMNT-DEL", "CONFIRM", "CONFIRM", onConfirm);
}


const delCmmnt = async (pCmmnt) => {
  const onConfirm = () => {
    cmmntLst.value = cmmntLst.value.filter(cmmnt => cmmnt.postNum !== pCmmnt.postNum);
    store.dispatch('alert/setAlert', {
        name: 'comm',
        show: false
    });


  };
    await $axios({
        method: 'DELETE',
        url: '/tripwith/post/user/' + pCmmnt.postNum,
    }).then((data) => {
        const resultObj = data.resultObj;
        if (data.resultYn == 'Y') {
          toggleCommAlert("CMMNT-DEL", "SUCCESS", "ALERT", onConfirm);
        }else{
          toggleCommAlert("CMMNT-DEL", "FAIL", "ALERT");
        }
    }).catch((error) => {
    toggleCommAlert("CMMNT-DEL", "FAIL", "ALERT");
    })
}

function convertTimestampToFormattedDate(timestamp) {
    // timestamp 값을 milliseconds로 변환
    const date = new Date(timestamp);

    // 원하는 형식으로 날짜를 포맷
    const options = { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit' 
    };
    
    // 로케일을 'ko-KR'로 설정하여 한국 형식으로 포맷
    const formattedDate = new Intl.DateTimeFormat('ko-KR', options).format(date);

    // 'yyyy. MM. dd' 포맷으로 반환
    return formattedDate.replace(/\. /g, '.');
}

const togglePostLike = async (pPostNum) => {

  if(!loginChk()){
    //로그인하지 않은 경우 함수의 실행 중단
    return;
  };

  const cmmnt = cmmntLst.value.find(c => c.postNum === pPostNum);
  if(cmmnt){
    if(!cmmnt.likedByUser){
      await goRegPostLike(cmmnt);
    }else{
      await goDelPostLike(cmmnt);
    }
  }
};

const goRegPostLike = async (pCmmnt) => {
    await $axios({
        method: 'POST',
        url: '/tripwith/post/' + pCmmnt.postNum + '/like',
    }).then((data) => {
        const resultYn = data.resultYn;
        if (resultYn == 'Y') {
          pCmmnt.likedByUser = true;
          pCmmnt.likeCount++;
        }
    }).catch((error) => {
        toggleCommAlert("COMM", "FAILED");
    })
}

const goDelPostLike = async (pCmmnt) => {
    await $axios({
        method: 'DELETE',
        url: '/tripwith/post/' + pCmmnt.postNum + '/like',
    }).then((data) => {
        const resultYn = data.resultYn;
        if (resultYn == 'Y') {
          pCmmnt.likedByUser = false;
          pCmmnt.likeCount--;
        }
    }).catch((error) => {
        toggleCommAlert("COMM", "FAILED");
    })
}


const togglePostDibs = async (pCmmnt) => {

  if(!loginChk()){
    //로그인하지 않은 경우 함수의 실행 중단
    return;
  };
  
  if(!pCmmnt.dibsedByUser){
    await goRegPostDibs(pCmmnt);
  }else{
    await goDelPostDibs(pCmmnt);
  }
};

const goRegPostDibs = async (pCmmnt) => {
    await $axios({
        method: 'POST',
        url: '/tripwith/post/' + pCmmnt.postNum + '/dibs',
    }).then((data) => {
        const resultYn = data.resultYn;
        if (resultYn == 'Y') {
          pCmmnt.dibsedByUser = true;
        }
    }).catch((error) => {
        toggleCommAlert("COMM", "FAILED");
    })
}

const goDelPostDibs = async (pCmmnt) => {
    await $axios({
        method: 'DELETE',
        url: '/tripwith/post/' + pCmmnt.postNum + '/dibs',
    }).then((data) => {
        const resultYn = data.resultYn;
        if (resultYn == 'Y') {
          pCmmnt.dibsedByUser = false;
        }
    }).catch((error) => {
        toggleCommAlert("COMM", "FAILED");
    })
}


// 스크롤 이벤트
//하단 스크롤 시 발생 이벤트 관련 변수 선언
const currentScroll = ref(0);
const waitingScroll = ref(false);

const scrollHandler = throttle(scrollCheck, 500);

onMounted(() => {
    document.addEventListener('scroll',scrollHandler);
})

onBeforeMount(() => {
    document.removeEventListener('scroll', scrollHandler);
})


function scrollCheck(e){
    currentScroll.value = e.target.scrollingElement.scrollTop + e.target.scrollingElement.clientHeight; 
    if(currentScroll.value > e.target.scrollingElement.scrollHeight- 200 && pageObj.totalPage > cmmnt.value.pageNum && !waitingScroll.value){
        GoGetListNextCmmnt(e);
    }
}

async function GoGetListNextCmmnt() {
    waitingScroll.value = true;
    const addCmmntLst = await goGetLstCmmnt(cmmnt.value);
    let i;
    for(i = 0; i < addCmmntLst.length; i++){
        cmmntLst.value.push(addCmmntLst[i]);
    }
    waitingScroll.value = false;
}

</script>
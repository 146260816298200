<template>
  <div class="recommended content">
    <div class="swiper tw-ConSwipe swiper-initialized swiper-horizontal swiper-backface-hidden">
      <div class="tw-body-input-wrap">
        <h2 class="tw-label-default bold bt-p">추천콘텐츠</h2>
      </div>


      <Swiper :slides-per-view="'auto'" :space-between="5" >
        <template  v-for="(tripinfo, index) in tripinfoLst" :key="index">
          <SwiperSlide>
            <div class="swiper-slide tw-swiper-txt swiper-slide-active" role="group" aria-label="1 / 10" >
              <div class="tw-info-tab-list swiper">
                <ul class="clearfix">
                  <li @click="goTripinfoDtl(tripinfo.tripinfoNum)">
                    <div class="tw-info-box">
                      <div class="tw-info-category">{{$t('user.main.TRIPINFO.TI-CTGRY.' + tripinfo.tripinfoCtgryCodeRef_1) }}</div>
                      <div class="tw-info-bookmark-icon" tabindex="0">북마크 아이콘</div>
                      <div class="tw-info-box-img">
                        <a v-if="tripinfo.thmbTripinfoFullPath">
                          <img :src="tripinfo.thmbTripinfoFullPath" alt="콘텐츠 이미지">
                        </a>
                        <a v-else>
                          <img :src="serverUrl + '/tripinfo/getImg/' + tripinfo.thmbTripinfoFileNum" alt="콘텐츠 이미지">
                        </a>
                      </div>
                      <div class="tw-info-box-txt">
                        <a>
                          <div class="info-txt-top">{{ tripinfo.title }}</div>
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </SwiperSlide>
        </template>
      </Swiper>
    </div>
  </div>

</template>


<script setup>
import { computed, getCurrentInstance, onMounted, ref } from 'vue';
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n';

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css'; // 기본 Swiper 스타일
import 'swiper/css/pagination'; // Swiper Pagination 스타일
import 'swiper/css/navigation'; // Swiper Navigation 스타일


import { useNavigation } from '@/page/main/js/useNavigation';
const { navigate } = useNavigation();

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const $axiosJson = proxy.$axiosJson;

const swiperRef = ref(null);
const store = useStore();

const tripinfoLst = ref([]);

const serverUrl = computed(() => store.getters.getServerUrl);

//부모 컴포넌트에서 받은 값
const props = defineProps({
  tripinfo: Object,
})

const goTripinfoDtl = (pTripinfoNum) => {
    navigate({value:"tripinfoDtl", querys: {tripinfoNum:pTripinfoNum} } );
}

const goGetTripinfoLst = async (tripinfoParam) => {
  let tmpTripinfoLst;
    await $axios({
        method: 'POST',
        data: tripinfoParam,
        url: '/tripinfo/lstpage',
    }).then((data) => {
        const resultObj = data.resultObj;
        if (resultObj) {
            tmpTripinfoLst = resultObj.content;
        }
    }).catch((error) => {
        alert("에러 발생");
    })
    return tmpTripinfoLst;
};

//언어 설정 부분
const { locale } = useI18n();

onMounted( async () => {
  //언어 추가
  const lang = computed(() => store.state.user.user.lang);
  props.tripinfo.lang = locale.value;
  props.tripinfo.pageNum = 0;
  props.tripinfo.pageSize = 10;
  tripinfoLst.value = await goGetTripinfoLst(props.tripinfo);
})



//부모 컨테이너에서 받은 값으로 리스트 조회
</script>

<template>
<div class="tw-pop-wrap delete" role="dialog" aria-modal="true" v-show="isAlertOpen">
    <div class="tw-pop-box">
      <div class="tw-body-text-wrap">
        <label class="tw-label-default f24">{{ $t("user.main.alert." + commAlertStore.title + ".TITLE") }}</label>
        <label class="tw-label-default" style="margin-bottom:4px;"> {{ $t("user.main.alert." + commAlertStore.title + "." + commAlertStore.code) }} </label>
      </div>
      <div class="tw-body-btn-wrap flex">
        <template v-if="commAlertStore.mode == 'ALERT'">
          <button v-if="commAlertStore.event" type="button" class="tw-btn-default primary" @click="onConfirm();" aria-label="확인 버튼">확인</button>
          <button v-else type="button" class="tw-btn-default primary" @click="closeAlert();" aria-label="확인 버튼">확인</button>
        </template>
        <template v-else>
          <button type="button" class="tw-btn-default border" @click="closeAlert();" aria-label="취소 버튼">취소</button>
          <button type="button" class="tw-btn-default primary" @click="onConfirm();" aria-label="확인 버튼">확인</button>
        </template>
      </div>
    </div>
</div>
<div class="tw-pop-bg" v-show="isAlertOpen">모달bg</div>
</template>

<script setup>
import { computed, getCurrentInstance, onMounted, ref } from 'vue';
import { createLogger, useStore } from "vuex";

const store = useStore();

const isAlertOpen = computed(() => store.state.alert.comm.show);
const commAlertStore = computed(() => store.state.alert.comm);


const closeAlert = () => {

    console.log(commAlertStore.value.event);

    store.dispatch('alert/setAlert', {
      name: 'comm',
      show: false
  });
}

const onConfirm = async () => {
  if (commAlertStore.value.event) {
    commAlertStore.value.event();
  }
  // store.commit('clearConfirm');
};

</script>


<template>
      <header class="tw-top">
        <a href="#" class="tw-top-icon" @click="goBack()" >뒤로가기 아이콘</a>
        <h1 class="tw-label-top">Tripinfo</h1>
      </header>
      <div class="tw-body p-0">
        <div class="tw-info-detail">
          <div class="tw-info-detail-top">
            <div class="detail-top-info-wrap">
              <div class="detail-top-info-img">
                <img src="/tripwith/assets/images/img-sample(5).png" alt="프로필 이미지">
              </div>
              <h2 class="detail-top-info-id">Team TripWith</h2>
              <div class="detail-top-info-date">2023.12.12</div>
            </div>
            <div class="detail-top-icon-wrap">
              <span class="tw-bookmark-icon on" tabindex="0">북마크 아이콘</span>
              <span class="tw-more-icon" aria-haspopup="true" tabindex="0">더보기 아이콘</span>
              <div class="tw-pop-mini"  role="dialog" aria-modal="true" v-show="bTripinfoDtlShow">
                <ul>
                  <li><a href="../2step/CT_CC_004.html">수정하기</a></li>
                  <li onclick="popOpen2();" tabindex="0"><a>삭제하기</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="tw-info-detail-body">
            <template v-if="tripinfo.videoUrl">
              <div class="detail-body-video">
                <iframe width="100%" height="300px" :src="tripinfo.videoUrl" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
            </template>
            <!-- <div class="detail-body-video">
              <figure>
                <video controls>
                  <source src="/tripwith/assets/video/sample.mp4" >
                </video>
                <figcaption>비디오 설명</figcaption>
              </figure>
            </div> -->
            <div class="detail-body-txt">

              <h2>{{ tripinfo.title }}</h2>
              <div class="tiptap" v-html="tripinfo.cntn"></div>
            </div>
          </div>
          <div class="tw-info-detail-btn">
            <div class="info-bt-like icon" tabindex="0">
              <span class="tw-like-icon">좋아요 아이콘</span>
              <span class="tw-like-txt">좋아요</span>
            </div>
            <div class="info-bt-comm icon" tabindex="0">
              <span class="tw-comm-icon">댓글 아이콘</span>
              <span class="tw-comm-txt">댓글달기</span>
            </div>
            <div class="info-bt-share icon" tabindex="0">
              <span class="tw-share-icon">공유하기 아이콘</span>
              <span class="tw-share-txt">공유하기</span>
            </div>
          </div>

          <div class="gray-blank"></div>

          <rcmn-tripinfo-swiper :tripinfo="tripinfo" />

          <div class="tw-info-detail-commentbox">
            <p>공백</p>
            <ul class="tw-comment-list-wrap">
              <!-- 댓글 열린상태 예시 -->
                <tripinfo-comm-cmnt></tripinfo-comm-cmnt>
            </ul>
          </div>
          <send-input :cmnt="tripinfoCmnt" @submit="regTripinfoCmnt"></send-input>

          <!-- <div class="tw-info-detail-send active">
            <div class="detail-send-img">
              <img src="/tripwith/assets/images/img-sample(5).png" alt="닉네임 프로필 이미지">
            </div>
            <div class="detail-send-input">
                <input type="text" class="tw-input-default tw-input-comment" v-model="tripinfoCmnt.cntn" @keydown.enter="regTripinfoCmntDeb" >
            </div>
            <div class="detail-send-icon on" tabindex="0">보내기</div>
          </div> -->
        </div>
      </div>
</template>


<script setup>

import { ref, onMounted, getCurrentInstance, computed, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n';

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const $axiosJson = proxy.$axiosJson;
const router = useRouter()
const route = useRoute()
const store = useStore();


//components
import tripinfoCard from '@/page/main/views/tripinfo/comm/cardLst.vue'
import tripinfoCommCmnt from '@/page/main/views/tripinfo/comm/cmntLst.vue'
import sendInput from '@/page/main/views/tripinfo/comm/sendInput.vue'

import { useNavigation } from '@/page/main/js/useNavigation';
const { navigate, goBack } = useNavigation();

import { useComm } from '@/page/main/js/comm';
const { toggleCommLayer, toggleCommAlert, loginChk } = useComm();

import rcmnTripinfoSwiper from '@/page/main/views/tripinfo/comm/rcmnTripinfoSwiper.vue'

const bTripinfoDtlShow = ref(false)

let tripinfoNum = route.query.tripinfoNum;
const tripinfo = ref({
  videoUrl : '',
  title : '',
  tripinfoNum : tripinfoNum,
});
const tripinfoCmnt = ref({tripinfoNum : tripinfoNum, cntn:''});
const tripinfoCmntLst = ref([]);

// 쿼리 파라미터 tripinfoNum의 변경을 감시
watch(() => route.query.tripinfoNum, (newVal, oldVal) => {
  // 새로운 tripinfoNum 값으로 데이터를 다시 불러옵니다.
  tripinfoNum = route.query.tripinfoNum;
  tripinfo.value.tripinfoNum = tripinfoNum;
  tripinfoCmnt.value.tripinfoNum = tripinfoNum;
  tripinfo.value.lang = locale.value;
  getTripinfoDtl(tripinfo.value, tripinfoCmnt.value);
});

const getTripinfoDtl = async (ptTripinfo, pTripinfoCmntLst) => {
  tripinfo.value = await goGetTripinfo(ptTripinfo);
  tripinfoCmntLst.value = await goGetTripinfoCmntLst(pTripinfoCmntLst);

  //store에 연동
  store.dispatch('tripinfo/setCmntLst', tripinfoCmntLst.value);
}

const goGetTripinfo = async (tripinfoParam) => {
  let tmpTripinfo;
    await $axios({
        method: 'POST',
        data: tripinfoParam,
        url: '/tripinfo/getview',
    }).then((data) => {
        const resultObj = data.resultObj;
        if (resultObj) {
            tmpTripinfo = resultObj;
        }
    }).catch((error) => {
        alert("데이터를 불러오는 도중 에러가 발생하였습니다. ");
    })
    return tmpTripinfo;
};

const goGetTripinfoCmntLst = async (tripinfoCmntParam) => {
  let tmpTripinfoCmntLst;
    await $axiosJson({
        method: 'POST',
        data: tripinfoCmntParam,
        url: '/tripinfo/cmnt/getLst',
    }).then((data) => {
        const resultObj = data.resultObj;
        if (resultObj) {
            tmpTripinfoCmntLst = resultObj;
        }
    }).catch((error) => {
        alert("데이터를 불러오는 도중 에러가 발생하였습니다. ");
    })
    return tmpTripinfoCmntLst;
};


const regTripinfoCmnt = async (e) => {

    if(!loginChk()){
      //로그인하지 않은 경우 함수의 실행 중단
      return;
    };

    await $axiosJson({
        method: 'POST',
        data: e,
        url: '/tripinfo/cmnt/reg',
    }).then((data) => {
        const resultObj = data.resultObj;
        if (data.resultYn == 'Y') {
          //tripinfoCmnt 내에 추가

          store.dispatch('tripinfo/pushCmnt', resultObj);

          tripinfoCmnt.value.cntn = '';
        }
    }).catch((error) => {
        alert("댓글 등록 중 에러가 발생하였습니다. ");
    })
}

//언어 설정 부분
const { locale } = useI18n();

onMounted( async () => {
  tripinfo.value.lang = locale.value;
  tripinfo.value = await goGetTripinfo(tripinfo.value);
  tripinfoCmntLst.value = await goGetTripinfoCmntLst(tripinfoCmnt.value);
  //store에 연동
  store.dispatch('tripinfo/setCmntLst', tripinfoCmntLst.value);
})


</script>
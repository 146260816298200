<template>
      <header class="tw-top">
        <a href="#" class="tw-top-icon" @click="goBack()" >뒤로가기 아이콘</a>
        <h1 class="tw-label-top">Tripinfo</h1>
      </header>
      <div class="tw-body p-0">
        <div class="tw-info-detail">
          <div class="tw-info-detail-commentbox">
            <ul class="tw-comment-list-wrap">
              <!-- 댓글 열린상태 예시 -->
                <tripinfo-comm-cmnt></tripinfo-comm-cmnt>
            </ul>
          </div>
          <send-input :cmnt="tripinfoCmnt" @submit="regTripinfoCmnt" ></send-input>
        </div>
      </div>
</template>


<script setup>

import { ref, onMounted, onBeforeMount, getCurrentInstance } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { createLogger, useStore } from "vuex";

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const $axiosJson = proxy.$axiosJson;
const router = useRouter()
const route = useRoute()
const store = useStore();


//components
import tripinfoCommCmnt from '@/page/main/views/tripinfo/comm/cmntLst.vue'
import sendInput from '@/page/main/views/tripinfo/comm/sendInput.vue'

import { debounce, throttle } from 'lodash'

import { useNavigation } from '@/page/main/js/useNavigation';
const { navigate, goBack } = useNavigation();

import { useComm } from '@/page/main/js/comm';
const { toggleCommLayer, toggleCommAlert, loginChk } = useComm();

const bTripinfoDtlShow = ref(false)

const selTripinfoNum = route.query.tripinfoNum;
const selTripinfoCmntNum = route.query.tripinfoCmntNum;

const tripinfoCmnt = ref({tripinfoNum : selTripinfoNum, upprTripinfoCmntModel : {tripinfoCmntNum:selTripinfoCmntNum}, cntn:''});
const tripinfoCmntLst = ref([]);

const goGetTripinfoCmntLst = async (tripinfoCmntParam) => {
  let tmpTripinfoCmntLst;
    await $axiosJson({
        method: 'POST',
        data: tripinfoCmntParam,
        url: '/tripinfo/cmnt/getLst',
    }).then((data) => {
        const resultObj = data.resultObj;
        if (resultObj) {
            tmpTripinfoCmntLst = resultObj;
        }
    }).catch((error) => {
        alert("데이터를 불러오는 도중 에러가 발생하였습니다. ");
    })
    return tmpTripinfoCmntLst;
};

const regTripinfoCmnt = async (e) => {

    if(!loginChk()){
      //로그인하지 않은 경우 함수의 실행 중단
      return;
    };

    await $axiosJson({
        method: 'POST',
        data: e,
        url: '/tripinfo/cmnt/reg',
    }).then((data) => {
        const resultObj = data.resultObj;
        if (data.resultYn == 'Y') {
          //tripinfoCmnt 내에 추가

          store.dispatch('tripinfo/pushCmnt', resultObj);

          tripinfoCmnt.value.cntn = '';
        }
    }).catch((error) => {
        alert("댓글 등록 중 에러가 발생하였습니다. ");
    })
}



onMounted( async () => {
  tripinfoCmntLst.value = await goGetTripinfoCmntLst(tripinfoCmnt.value);
  //store에 연동
  store.dispatch('tripinfo/setCmntLst', tripinfoCmntLst.value);
})


</script>
import { createRouter, createWebHistory } from 'vue-router'

import baseView from '@/page/main/views/baseView.vue';
import home from '@/page/main/views/home/main.vue';
import profile from '@/page/main/views/mypage/profile_backup.vue';

import login from '@/page/main/views/login/login.vue';
import mypageMain from '@/page/main/views/mypage/main.vue';
import mypageMod from '@/page/main/views/mypage/mod.vue';
import mypageLang from '@/page/main/views/mypage/lang.vue';

import naverLoginCallback from '@/page/main/views/login/smpl/naverLoginCallback.vue';
import kakaoLoginCallback from '@/page/main/views/login/smpl/kakaoLoginCallback.vue';
import googleLoginCallback from '@/page/main/views/login/smpl/googleLoginCallback.vue';
import facebookLoginCallback from '@/page/main/views/login/smpl/facebookLoginCallback.vue';

import tripinfoMain from '@/page/main/views/tripinfo/main.vue';
import tripinfoDtl from '@/page/main/views/tripinfo/dtl.vue';
import tripinfoCmnt from '@/page/main/views/tripinfo/cmnt.vue';
import tripinfoSrch from '@/page/main/views/tripinfo/srch.vue';
import tripinfoFilter from '@/page/main/views/tripinfo/filter.vue';

import cmmntMain from '@/page/main/views/cmmnt/main.vue';
import cmmntFilter from '@/page/main/views/cmmnt/filter.vue';
import cmmntWrite from '@/page/main/views/cmmnt/write.vue';
import cmmntSrch from '@/page/main/views/cmmnt/srch.vue';
import cmmntDtl from '@/page/main/views/cmmnt/dtl.vue';
import cmmntCmnt from '@/page/main/views/cmmnt/cmnt.vue';

const requireAuth = () => (to, from, next) => {
  let token = localStorage.getItem('authorization');
  console.log('token = ' + token);
  if (token != null) {
    return next();
  }
  location.href = '/mypage';
};

const routes = [
  {
    path: '/',
    name: 'baseView',
    component: baseView,
    children: [
      {
        path: '/',
        alias: ['/main', '/index', '/', ''],
        name: 'home',
        component: home,
        props: true,
      },
      {
        path: '/cmmnt',
        alias: ['/cmmntMain'],
        name: 'cmmnt',
        component: cmmntMain,
        props: true,
      },
      {
        path: '/cmmnt/filter',
        alias: ['/cmmntFilter'],
        name: 'cmmntFilter',
        component: cmmntFilter,
        props: true,
      },
      {
        path: '/cmmnt/write/:postNum?',
        name: 'cmmntWrite',
        component: cmmntWrite,
        props: true,
      },
      {
        path: '/cmmnt/srch',
        name: 'cmmntSrch',
        component: cmmntSrch,
        props: true,
      },
      {
        path: '/cmmnt/:postNum',
        name: 'cmmntDtl',
        component: cmmntDtl,
        props: true,
      },
      {
        path: '/cmmnt/cmnt/:postNum/:cmntNum',
        name: 'cmmntCmnt',
        component: cmmntCmnt,
        props: true,
      },
      {
        path: '/profile',
        name: 'profile',
        component: profile,
        props: true,
      },
      {
        path: '/login',
        name: 'login',
        component: login,
        props: true,
      },
      {
        path: '/mypage/mod',
        name: 'mypageMod',
        component: mypageMod,
        props: true,
        beforeEnter: requireAuth() 
      },
      {
        path: '/mypage/lang',
        name: 'mypageLang',
        component: mypageLang,
        props: true,
        beforeEnter: requireAuth() 
      },
      {
        path: '/mypage',
        name: 'mypageMain',
        component: mypageMain,
        props: true,
      },
      {
        path: '/login/naver',
        name: 'loginNaver',
        component: naverLoginCallback,
        props: true,
      },
      {
        path: '/login/kakao',
        name: 'loginKakao',
        component: kakaoLoginCallback,
        props: true,
      },
      {
        path: '/login/google',
        name: 'loginGoogle',
        component: googleLoginCallback,
        props: true,
      },
      {
        path: '/login/facebook',
        name: 'loginFacebook',
        component: facebookLoginCallback,
        props: true,
      },
      {
        path: '/tripinfo/main',
        name: 'tripinfo',
        component: tripinfoMain,
        props: true,
      },
      {
        path: '/tripinfo/dtl',
        name: 'tripinfoDtl',
        component: tripinfoDtl,
        props: true,
      },
      {
        path: '/tripinfo/cmnt',
        name: 'tripinfoCmnt',
        component: tripinfoCmnt,
        props: true,
      },
      {
        path: '/tripinfo/srch',
        name: 'tripinfoSrch',
        component: tripinfoSrch,
        props: true,
      },
      {
        path: '/tripinfo/filter',
        name: 'tripinfoFilter',
        component: tripinfoFilter,
        props: true,
      },
    ]
  },
  // 존재하지 않는 모든 경로 처리
  {
    path: '/:catchAll(.*)',
    redirect: '/',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  //base : process.env.BASE_URL,
  routes
})

export default router

<template>

      <header class="tw-top icon">
        <h1 class="tw-label-top">Trip Info</h1>
        <div class="tw-top-right">
          <a class="tw-right-icon" @click="navigate({value:'tripinfoFilter'})">검색 아이콘</a>
          <!-- <a class="tw-alarm-icon">알림 아이콘</a> -->
        </div>
      </header>

      <div class="tw-body main">
        <div class="tw-main-banner">
          <div class="swiper bannerSwiper tw-swiper-key tw-swiper-del swiper-initialized swiper-horizontal swiper-backface-hidden">
            <div class="swiper-wrapper" id="swiper-wrapper-1fa77efdd1047f4f5" aria-live="polite">
                <div class="swiper-slide tw-swiper-txt swiper-slide-active" role="group" aria-label="1 / 10">
                  <div class="banner-corver">
                    <p class="banner-txt-trip">어서오세요 트립 위드입니다.</p>
                    <h2 class="banner-txt-bt">한국 여행을 위한 교통, 관광명소 등 <br> 다양한 가이드를 소개합니다.</h2>
                  </div>
                  <img src="/tripwith/assets/images/img-main.png" alt="메인 배너 01">
                </div>
                <div class="swiper-slide tw-swiper-txt swiper-slide-next" role="group" aria-label="2 / 10">
                  <div class="banner-corver">
                    <p class="banner-txt-trip">어서오세요 트립 위드입니다.</p>
                    <h2 class="banner-txt-bt">한국 여행을 위한 교통, 관광명소 등 <br> 다양한 가이드를 소개합니다.</h2>
                  </div>
                  <img src="/tripwith/assets/images/img-main.png" alt="메인 배너 02">
                </div>
                <div class="swiper-slide tw-swiper-txt" role="group" aria-label="3 / 10">
                  <div class="banner-corver">
                    <p class="banner-txt-trip">어서오세요 트립 위드입니다.</p>
                    <h2 class="banner-txt-bt">한국 여행을 위한 교통, 관광명소 등 <br> 다양한 가이드를 소개합니다.</h2>
                  </div>
                  <img src="/tripwith/assets/images/img-main.png" alt="메인 배너 03">
                </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
        <div class="tw-main-info">
          <div class="tw-info-align">
            <h2 class="tw-label-title none">Info Category</h2>
          </div>
          <div class="tw-main-conbox-wrap info">
            <nav>
              <ul class="tw-main-conbox">
                <li class="tw-main-con">
                  <a>
                    <p class="tw-con-tv-icon">Trip TV 아이콘</p>
                    <p class="tw-con-txt">Trip TV</p>
                  </a>
                </li>
                <li class="tw-main-con">
                  <a>
                    <p class="tw-con-blog-icon">Trip Blog 아이콘</p>
                    <p class="tw-con-txt">Trip Blog</p>
                  </a>
                </li>
                <li class="tw-main-con">
                  <a>
                    <p class="tw-con-sos-icon">S.O.S 아이콘</p>
                    <p class="tw-con-txt">S.O.S</p>
                  </a>
                </li>
                <li class="tw-main-con">
                  <a>
                    <p class="tw-con-here-icon">Go Here 아이콘</p>
                    <p class="tw-con-txt">Go Here</p>
                    </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="tw-main-tab p-0">
          <ul class="main-tabs info">
            <li class="tab-link" :class="curTab == '001' ? 'current' : ''" tabindex="0" @click="tabbarClick('001')" >{{ $t('user.main.COMM.COMM-ORDER.001') }}</li>
            <li class="tab-link" :class="curTab == '002' ? 'current' : ''" tabindex="0" @click="tabbarClick('002')" >{{ $t('user.main.COMM.COMM-ORDER.002') }}</li>
            <li class="tab-link" :class="curTab == '003' ? 'current' : ''" tabindex="0" @click="tabbarClick('003')" >{{ $t('user.main.COMM.COMM-ORDER.003') }}</li>
            <li class="tab-link" :class="curTab == '004' ? 'current' : ''" tabindex="0" @click="tabbarClick('004')" >{{ $t('user.main.COMM.COMM-ORDER.004') }}</li>
          </ul>

          <template v-for="(tab, index) in tabs" :key="index">
            <div id="m-tab-1" class="tab-content" :class="curTab == tab ? 'current' : ''">
              <div class="tw-info-tab-list">
                <ul class="clearfix">
                  <tripinfo-card :tripinfoLst="tripInfoLists[tab]" ></tripinfo-card>
                </ul>
              </div>
            </div>
          </template>

<!--           
          <div id="m-tab-1" class="tab-content" :class="curTab == tabs[0] ? 'current' : ''">
            <div class="tw-info-tab-list">
              <ul class="clearfix">
                <tripinfo-card :tripinfoLst="tripInfoLists[tabs[0]]" ></tripinfo-card>
              </ul>
            </div>
          </div>
          <div id="m-tab-2" class="tab-content" :class="selTab == 'VIEW' ? 'current' : ''">
            <div class="tw-info-tab-list">
              <ul class="clearfix">
                <tripinfo-card :tripinfoLst="viewTripinfoLst" ></tripinfo-card>
              </ul>
            </div>
          </div>
          <div id="m-tab-3" class="tab-content" :class="selTab == 'NWST' ? 'current' : ''">
            <div class="tw-info-tab-list">
              <ul class="clearfix">
                <tripinfo-card :tripinfoLst="nwstTripinfoLst" ></tripinfo-card>
              </ul>
            </div>
          </div>
          <div id="m-tab-4" class="tab-content" :class="selTab == 'MY' ? 'current' : ''">
            <div class="tw-info-tab-list">
              <ul class="clearfix">
                <tripinfo-card :tripinfoLst="myTripinfoLst" ></tripinfo-card>
              </ul>
            </div>
          </div> -->



        </div>
      </div>
</template>


<script setup>

import { ref, onMounted, onBeforeMount, getCurrentInstance, reactive,computed } from 'vue'
import { useRouter } from 'vue-router';
import { createLogger, useStore } from "vuex";
import { useI18n } from 'vue-i18n';

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const router = useRouter()
const store = useStore();

//언어 설정 부분
const { locale } = useI18n();

//공통 js import
import { useNavigation } from '@/page/main/js/useNavigation';
const { navigate } = useNavigation();

//components
import tripinfoCard from '@/page/main/views/tripinfo/comm/cardLst.vue'

const pageObj = reactive({totalCnt : 0 //최대 리스트 개수
                    ,totalPage : 10 // 최대 페이지 개수 
                    ,pageNum : 0 //현재 페이지 번호(실제 페이지 넘버 -1)
                    ,pageSize : 10 //보여줄 페이징의 최대 개수
                    ,pageBlockNum : 8 //한 페이지 내에 보여줄 리스트 개수
                    })

const tripinfo = ref({});
const tabs = ['001', '002', '003', '004'];
const curTab = ref(tabs[0]);

tripinfo.value.lang = locale.value;
tripinfo.value.pageNum = 0;
tripinfo.value.pageSize = 10;
tripinfo.value.tripinfoOrderCtgryCode = tabs[0];

const tripInfoLists = reactive({
  '001': [],
  '002': [],
  '003': [],
  '004': []
});


const goGetTripinfoLst = async (tripinfoParam) => {
  let tmpTripinfoLst;
    await $axios({
        method: 'POST',
        data: tripinfoParam,
        url: '/tripinfo/lstpage',
    }).then((data) => {
        const resultObj = data.resultObj;
        if (resultObj) {
            tmpTripinfoLst = resultObj.content;
        }
    }).catch((error) => {
        alert("에러 발생");
    })
    return tmpTripinfoLst;
};

const tabbarClick = async (pTab) => {
  curTab.value = pTab;
  if (tripInfoLists[pTab].length === 0) {
    tripinfo.value.tripinfoOrderCtgryCode = pTab;
    tripInfoLists[pTab] = await goGetTripinfoLst(tripinfo.value);
  }
};

onMounted( async () => {
  //언어 추가
  tripInfoLists['001'] = await goGetTripinfoLst(tripinfo.value);
})

</script>
<template>
</template>

<script setup>

import { ref, onMounted, onBeforeMount, getCurrentInstance } from 'vue'
import { useRouter,useRoute } from 'vue-router';
import { useStore } from "vuex";
import cnst from '@/util/cnst.js'

//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const router = useRouter()
const route = useRoute()
const store = useStore();

import { useNavigation } from '@/page/main/js/useNavigation';
const { navigate } = useNavigation();

let loginMethod = 'KAKAO'; 
let loginCode = cnst.SMPL[loginMethod].SMPL_LOGIN_CODE;

onMounted(async () => {
    let userInfo = new Object;
    userInfo.authorizationCode = route.query.code,
    userInfo.smplLoginCode = loginMethod;

    //해당 엑세스토큰으로 서버단에서 정보 요청을 하는 방식으로 진행할 예정.
    await $axios({
        method: 'POST',
        data: userInfo,
        url: '/user/smplLogin',
        }).then((data) => {
            var resultObj =  data.resultObj;
            if( resultObj != null){
                localStorage.setItem('authorization', resultObj.token);
                const user = {
                    smplLoginCode: loginMethod,
                    bLoginChk: true,
                    gndr : resultObj.gndr,
                    loginGbnCode : cnst.SMPL.NORMAL.USER_REG_TYPE_CODE,
                    userNum : resultObj.userNum,
                    nick : resultObj.nick,
                    prflFileFullPath : resultObj.prflFileFullPath,
                };
                store.dispatch('user/setUser', user);
                navigate({ value:'mypageMain' })
            }
        }).catch(error => {
            alert("로그인에 실패하였습니다. ");
            navigate({ value:'mypageMain' })
        });
})

</script>
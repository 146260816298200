<template>
        <header class="tw-top">
          <a href="../1step/FR_003.html" class="tw-top-icon">뒤로가기 아이콘</a>
          <h1 class="tw-label-top">회원정보</h1>
        </header>

        <div class="tw-body p-bt-20">
          <div v-show="bLoginChk">
            <div class="tw-profile">
              <div class="tw-profile-top">
                <div class="tw-profile-box">
                  <div class="profile-fileupload" tabindex="0">
                    <img v-if="sUser.prflFileFullPath" :src="sUser.prflFileFullPath" alt="프로필 사진" width="96" height="96">
                    <img v-else src="/tripwith/assets/images/img-profile.png" alt="프로필 사진" width="96" height="96">
                  </div>
                </div>
                <h2 class="tw-profile-neme"> {{ sUser.nick }}</h2>
              </div>
              <!-- <div class="tw-profile-bt">
                <div class="bt-box profile-gender">
                  <div class="txt-top">성별</div>
                  <div class="txt-bt">여자</div>
                </div>
                <div class="bt-box profile-nation">
                  <div class="txt-top">국적</div>
                  <div class="txt-bt">오스트레일리아</div>
                </div>
                <div class="bt-box profile-age">
                  <div class="txt-top">나이</div>
                  <div class="txt-bt">1993.12.19</div>
                </div>
              </div> -->
              <span class="tw-profile-modify">
                <a class="tw-btn-text" @click="navigate({ value:'mypageMod' })" >회원정보 수정</a>
              </span>
            </div>
            <div class="tw-body-line-wrap m-10 p-0">
              <p>라인</p>
            </div>
            <nav>
              <ul class="tw-my-list-box p-0">
                <!-- <li class="my-list">
                  <a>
                    <img src="/tripwith/assets/images/img-my-trip.png" alt="여행기본정보 이미지" width="48" height="48">
                    <span class="my-list-txt">여행기본정보</span>
                  </a>
                </li> -->
                <li class="my-list">
                  <a>
                    <img src="/tripwith/assets/images/img-my-save.png" alt="나의 찜" width="48" height="48">
                    <span class="my-list-txt">나의 찜</span>
                  </a>
                </li>
                <li class="my-list">
                  <a href="../3step/PB_NC_001.html">
                    <img src="/tripwith/assets/images/img-my-writing.png" alt="내가 작성한 글 이미지" width="48" height="48">
                    <span class="my-list-txt">내가 작성한 글</span>
                  </a>
                </li>
                <li class="my-list">
                  <a> 
                    <img src="/tripwith/assets/images/img-my-heart.png" alt="나의 좋아요 이미지" width="48" height="48">
                    <span class="my-list-txt">나의 좋아요</span>
                  </a>
                </li>
                <!-- <li class="my-list p-bt-10">
                  <a>
                    <img src="/tripwith/assets/images/img-my-follow.png" alt="팔로우 이미지" width="48" height="48">
                    <span class="my-list-txt">팔로우</span>
                  </a>
                </li>
                <li class="my-list p-bt-10">
                  <a href="../3step/PB_NC_001.html">
                    <img src="/tripwith/assets/images/img-my-warning.png" alt="나의 신고 이미지" width="48" height="48">
                    <span class="my-list-txt">나의 신고</span>
                  </a>
                </li>
                <li class="my-list p-bt-10">
                  <a href="../3step/MP_AC_001.html">
                    <img src="/tripwith/assets/images/img-my-trophy.png" alt="나의 업적 이미지" width="48" height="48">
                    <span class="my-list-txt">나의 업적</span>
                  </a>
                </li> -->
              </ul>
            </nav>

            <div class="tw-body-line-wrap m-10 p-0">
              <p>라인</p>
            </div>
        </div>

        <div v-show="!bLoginChk">
          <div class="tw-body-input-wrap">
            <label class="tw-label-default essential">이메일 주소</label>
            <div class="tw-input-wrap">
              <input type="text" class="tw-input-default" placeholder="이메일 주소를 입력해주세요.">
              <span class="tw-input-icon">input아이콘</span>
            </div>
          </div>
          <div class="tw-body-input-wrap">
            <label class="tw-label-default essential">비밀번호</label>
            <div class="tw-pw-wrap">
              <input type="password" class="tw-input-default" autocomplete="current-password" placeholder="비밀번호를 입력해주세요.">
              <span class="tw-pw-icon">pw아이콘</span>
            </div>
          </div>
          <div class="tw-body-btn-wrap">
            <button type="button" class="tw-btn-default primary">로그인</button>
            <button type="button" class="tw-btn-default border">회원가입</button>
          </div>
          <div class="tw-body-pw-wrap">
            <button type="button" class="tw-btn-text no-line"> 이메일로 비밀번호 찾기 </button>
            <label class="tw-label-small"> ｜ </label>
            <button type="button" class="tw-btn-text no-line"> 전화번호로 비밀번호 찾기 </button>
          </div>
          <div class="tw-body-line-wrap">
            <p>라인</p>
            <div>
              <label class="tw-label-title">간편하게 시작하기</label>
            </div>
          </div>
          <div class="tw-body-sns-wrap">
            <button type="button" class="tw-btn-sns" @click="clickKakaoSmplLoginBtn">
              <span class="tw-kko-icon">카카오톡로고</span>
            </button>
            <button type="button" class="tw-btn-sns" @click="clickNaverSmplLoginBtn">
              <span class="tw-nav-icon">네이버로고</span>
            </button>
            <button type="button" class="tw-btn-sns" @click="clickGoogleSmplLoginBtn">
              <span class="tw-goo-icon">구글로고</span>
            </button>
            <!-- <button type="button" class="tw-btn-sns" @click="clickFacebookSmplLoginBtn" >
              <span class="tw-fac-icon">페이스북로고</span>
            </button>
            <button type="button" class="tw-btn-sns">
              <span class="tw-app-icon">애플로고</span>
            </button>
            <button type="button" class="tw-btn-sns">
              <span class="tw-wec-icon">위챗로고</span>
            </button> -->
          </div>
        </div>




          <div class="tw-setting-box p-20-0">
            <h2 class="tw-label-default bold f16 m-bt-20">트립 환경</h2>
            <ul class="tw-setting-list-box main" >
              <li class="default-list" @click="navigate({ value:'mypageLang' })"><a>언어 설정</a></li>
              <li class="default-list"><a href="../2step/MB_MP_001_국가.html">국가 설정</a></li>
              <li class="default-list"><a href="../2step/MB_MP_001_날씨1.html">날씨</a></li>
              <li class="default-list"><a href="../2step/MB_MP_001_환율.html">환율</a></li>
              <li class="default-list"><a href="../2step/MB_MP_001_시차1.html">현지 시간 / 시차</a></li>
              <!-- <li class="default-list"><a href="../2step/MB_MP_001_통화.html">통화 설정</a></li> -->
              <li class="default-list"><a href="../3step/PB_NC_002.html ">알림 설정</a></li>
              <!-- <li class="default-list"><a href="../2step/MB_MP_001_단위.html">단위 설정</a></li> -->
            </ul>
          </div>

          <div class="tw-body-line-wrap m-10 p-0">
            <p>라인</p>
          </div>

          <div class="tw-setting-box p-20-0">
            <h2 class="tw-label-default bold f16 bt-p">트립 설정</h2>
            <ul class="tw-setting-list-box main" >
              <li class="default-list" v-if="bLoginChk" ><a @click="logout" >로그아웃</a></li>
              <li class="default-list"><a href="">이용약관</a ></li>
              <li class="default-list"><a href="">개인정보방침</a></li>
              <li class="default-list"><a href="../2step/MB_MP_001_문의1.html">1:1문의</a></li>
              <li class="default-list"><a href="../3step/PB_NC_001.html">공지사항</a></li>
              <li class="default-list"><a href="../2step/MB_MP_001_FAQ.html">FAQ</a></li>
              <li class="default-list" v-if="bLoginChk" ><a href="../2step/MB_LV_001.html">회원탈퇴</a></li>
            </ul>
          </div>
        </div>
</template>


<script setup>

import { ref, onMounted, onBeforeMount, getCurrentInstance, computed } from 'vue'
import { useRouter } from 'vue-router';
import { createLogger, useStore } from "vuex";
//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const router = useRouter()
const store = useStore();

import { useNavigation } from '@/page/main/js/useNavigation';
const { navigate, goBack } = useNavigation();

//유저 정보 처리 시작
import cnst from '@/util/cnst';

const user = ref({});
const userId = ref(null);
const userPw = ref(null);
const frontUrl = process.env.VUE_APP_FRONT_URL;
const serverUrl = computed(() => store.getters.getServerUrl);

user.value.id = "";
user.value.pw = "";
user.value.gbn = cnst.USER.GBN.USER;

const bLoginChk = computed(() => store.state.user.user.bLoginChk);
const sUser = computed(() => store.state.user.user);

async function goLogin() {
    if(user.value.id == ""){
        userId.value.focus()
        return false;
    }

    if(user.value.pw == ""){
        userPw.value.focus()
        return false;
    }

    await $axios({
        method: 'POST',
        data: user.value,
        url: '/user/login',
        }).then((data) => {
            store.dispatch('cust/setCust', cnst.SMPL.NORMAL.SMPL_LOGIN_CODE);
            goRouterByName('main');
        }).catch(error => {
            alert("로그인에 실패하였습니다. ");
        });
}

//네이버 간편로그인
function clickNaverSmplLoginBtn(){
    const naverUrl = 'https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id='
        + cnst.SMPL.NAVER.CLIENT_ID
        + '&state=STATE_STRING&redirect_uri='
        + frontUrl + '/' + cnst.SMPL.NAVER.CALLBACK_URL;

    window.location.href = naverUrl;
}

function clickKakaoSmplLoginBtn(){
    const kakaoUrl = 'https://kauth.kakao.com/oauth/authorize?client_id='
        + cnst.SMPL.KAKAO.CLIENT_ID
        + '&state=STATE_STRING&redirect_uri='
        + frontUrl + '/' + cnst.SMPL.KAKAO.CALLBACK_URL
        + '&response_type=code';

    window.location.href = kakaoUrl;
}

function clickGoogleSmplLoginBtn(){
    const googleUrl = 'https://accounts.google.com/o/oauth2/v2/auth?client_id=' 
        + cnst.SMPL.GOOGLE.CLIENT_ID
        + '&redirect_uri='
        + frontUrl + '/' + cnst.SMPL.GOOGLE.CALLBACK_URL
        + '&response_type=code'
        + '&scope=email profile';
    window.location.href = googleUrl;
}

function clickFacebookSmplLoginBtn(){
    const facebookUrl = "https://www.facebook.com/v11.0/dialog/oauth?client_id="
                  + cnst.SMPL.FACEBOOK.CLIENT_ID
                  + "&redirect_uri="
                  + cnst.SMPL.FACEBOOK.CALLBACK_URL
                  + "&response_type=code"
                  + "&scope=email,public_profile";
    window.location.href = facebookUrl;
}

const logout = () => {
  localStorage.removeItem("authorization");
  location.reload();
}

onMounted(() => {
    window.fbAsyncInit = function() {
    window.FB.init({
      appId      : cnst.SMPL.FACEBOOK.CLIENT_ID,
      cookie     : true,
      xfbml      : true,
      version    : 'v11.0'
    });
    window.FB.AppEvents.logPageView();
    };
})



</script>
// useNavigation.js
import { useRouter } from 'vue-router';

export function useNavigation() {
  const router = useRouter();

  function navigate({ type = 'name', value, querys, params }) {
    const routeConfig = { [type]: value, query: querys, params: params };
    router.push(routeConfig).then(() => window.scrollTo(0, 0));
  }

  function goBack() {
    router.back();
  }

  return { navigate, goBack };
}
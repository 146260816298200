var CMMNT = {
    SRCH : {
        CTGRY : {
            storeName : "cmmnt",
            stateName : "srch",
            funcName : "setCmmntFromModal",
            valueName : "postCtgryCode",
            langPath : 'user.main.CMMNT.POST-CTGRY.',
            lst : ['000','001', '002', '003', '004', '005', '006']
        }
    },
    FILTER : {
        CTGRY :{
            storeName : "cmmnt",
            stateName : "filter",
            funcName : "setCmmntFromModal",
            valueName : "postCtgryCode",
            langPath : 'user.main.CMMNT.POST-CTGRY.',
            lst : ['000','001', '002', '003', '004', '005', '006']
        }
    }
}

export default CMMNT;
<template>
  <transition name="modal">
  <div class="tw-pop-select" role="dialog" aria-modal="true" v-show="isModalOpen">
    <div class="tw-body-text-wrap">
      <h2 class="tw-label-default f20 bold">{{ $t(comm.langPath + 'TITLE') }}</h2>
      <button type="button" class="tw-close-btn" @click="closeModal" aria-label="닫기 버튼"> 닫기 </button>
    </div>
    <div class="tw-body-btn-wrap">
      <ul>
        <li v-for="(model, index) in lst " :key="index" @click="clickValue(model)"><a tabindex="0">{{ $t(comm.langPath + model) }}</a></li>
      </ul>
    </div>
  </div>
  </transition>
  <div class="tw-pop-bg" v-show="isModalOpen">모달bg</div>
</template>



<script setup>
import { computed, getCurrentInstance, onMounted, ref } from 'vue';
import { createLogger, useStore } from "vuex";

const store = useStore();

const isModalOpen = computed(() => store.state.layer.comm.show);
const lst = computed(() => store.state.layer.comm.lst);
const comm = computed(() => store.state.layer.comm);

const clickValue = (pValue) => {
    const data = {};
    data.key = comm.value.valueName;
    data.stateName = comm.value.stateName;
    data.value = pValue;
    store.dispatch(comm.value.storeName + "/" + comm.value.funcName, data);
    closeModal();
  }

const closeModal = () => {
    store.dispatch('layer/setLayerLst', {
      name: 'comm',
      show: false
  });
}
</script>


<style>
.modal-enter-active {
  animation: popup 0.3s ease-in-out forwards;
}

.modal-leave-active {
  animation: popdown 0.7s ease-out forwards;
}

@keyframes popup {
  0% {
    transform: translate(-50%, 1000px);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}
@keyframes popdown {
  0% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 1000px);
    opacity: 0;
  }
}
</style>
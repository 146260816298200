<template>

      <header class="tw-top">
          <a class="tw-top-icon" @click="goBack()">뒤로가기 아이콘</a>
        <h1 class="tw-label-top">커뮤니티</h1>
      </header>

      <div class="tw-body p-0">
        <div class="tw-info-detail">
          <div class="tw-info-detail-top">
            <div class="detail-top-info-wrap">
              <div class="detail-top-info-img">
                <img :src="cmmnt.fullPath" alt="닉네임 프로필 이미지">
              </div>
              <div class="detail-top-info-id">{{ cmmnt.nick }}
                <!-- <span class="id-badge">
                  <img src="/tripwith/assets/images/img-level-total-1.svg" alt="뱃지 이미지">
                </span> -->
              </div>
              <div class="detail-top-info-date">{{ convertTimestampToFormattedDate(cmmnt.regDt) }}</div>
            </div>    
            <div class="detail-top-icon-wrap">
              <span :class="['tw-bookmark-icon', { on: cmmnt.dibsedByUser }]" tabindex="0" @click="togglePostDibs(cmmnt)">북마크 아이콘</span>

              <template v-if="cmmnt.userNum == sUser.userNum">
                <span class="tw-more-icon" aria-haspopup="true" tabindex="0" @click="clickMoreIcon()">더보기 아이콘</span>
                <div class="tw-pop-mini"  role="dialog" aria-modal="true" v-show="bDtlShow" v-click-outside="onClickOutside">
                  <ul>
                    <li><a @click="goCmmntWrite">수정하기</a></li>
                    <li><a @click="delCmmntConfirm" >삭제하기</a></li>
                  </ul>
                </div>
              </template>

            </div>
          </div>
          <div class="tw-info-detail-body">
            <div class="detail-body-txt">
              <h2>{{ cmmnt.title}}</h2>
              {{ cmmnt.cntn }}
            </div>
  
            <div class="detail-body-img-wrap">
              <ul>
                <li v-for="(img, index) in imgFiles" :key="index">
                  <div> <img :src="img.fullPath" alt="첨부 이미지"> </div>
                </li>
              </ul>
            </div>
            <!-- <div class="detail-vote-attend-wrap">
              <div class="detail-vote-attend-box">
                <div class="vote-attend-top">
                  <span class="tw-vote-ing-icon">투표 아이콘</span>
                  <span class="tw-icon-text bold">투표</span>
                  <span class="tw-icon-text">10명 참여</span>
                </div>
                <div class="vote-attend-list">
                  <form>
                    <ul>
                      <li>
                        <button type="button" class="tw-vote-btn" aria-label="투표 선택 버튼">
                          <label for="agree1" class="radio_box">
                            <input type="radio" id="agree1" name="agree" value="동의" checked="" aria-label="투표 선택 라디오 버튼">
                            <span class="on2"></span>
                            여행 콘텐츠 부족
                          </label>
                        </button>
                      </li>
                      <li>
                        <button type="button" class="tw-vote-btn" aria-label="투표 선택 버튼">
                          <label for="agree2" class="radio_box">
                            <input type="radio" id="agree2" name="agree" value="동의" checked="" aria-label="투표 선택 라디오 버튼">
                            <span class="on2"></span>
                            여행 콘텐츠 부족
                          </label>
                        </button>
                      </li>
                      <li>
                        <button type="button" class="tw-vote-btn" aria-label="투표 선택 버튼">
                          <label for="agree3" class="radio_box">
                            <input type="radio" id="agree3" name="agree" value="동의" checked="" aria-label="투표 선택 라디오 버튼">
                            <span class="on2"></span>
                            여행 콘텐츠 부족
                          </label>
                        </button>
                      </li>
                    </ul>
                  </form>
                </div>
                <div class="tw-body-btn-wrap flex">
                  <button type="submit" class="tw-btn-default primary" aria-label="투표하기 버튼">투표하기</button>
                  <button type="button" class="tw-btn-default border" aria-label="결과보기 버튼">결과보기</button>
                </div>
              </div>
            </div> -->
  
            <!-- <div class="detail-vote-attend-wrap result">
              <div class="detail-vote-attend-box">
                <div class="vote-attend-top">
                  <span class="tw-vote-ing-icon">투표 아이콘</span>
                  <span class="tw-icon-text bold">투표</span>
                  <span class="tw-icon-text">10명 참여</span>
                  <button type="button" class="tw-btn-text" aria-label="다시 투표하기 버튼"> 다시 투표하기 </button>
                </div>
                <div class="vote-attend-list">
                  <ul>
                    <li>
                      <div class="vote-result-box" id="progressbar1">
                        <span class="result-txt-left">본 서비스 목적 이용 사라짐</span>
                        <span class="result-txt-right">4명</span>
                      </div>
                    </li>
                    <li>
                      <div class="vote-result-box" id="progressbar2">
                        <span class="result-txt-left">본 서비스 목적 이용 사라짐</span>
                        <span class="result-txt-right">2명</span>
                      </div>
                      
                    </li>
                    <li>
                      <div class="vote-result-box" id="progressbar3">
                        <span class="result-txt-left">본 서비스 목적 이용 사라짐</span>
                        <span class="result-txt-right">1명</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div> -->
  
          </div>
          <div class="tw-info-detail-btn">
              <div
                :class="['info-bt-like icon', { on: cmmnt.likedByUser }]"
                tabindex="0"
                @click="togglePostLike(cmmnt)"
              >
                <span class="tw-like-icon">좋아요 아이콘</span>
                <span class="tw-like-txt">{{ cmmnt.likeCount > 0 ? cmmnt.likeCount : '좋아요'}}</span>
              </div>
            <div class="info-bt-comm icon" :class="cmmnt.cmntCount > 0 ? 'on' : ''" tabindex="0">
              <span class="tw-comm-icon">댓글 아이콘</span>
              <span class="tw-comm-txt">{{ cmmnt.cmntCount > 0 ? cmmnt.cmntCount : '댓글달기'}}</span>
            </div>
            <div class="info-bt-share icon" tabindex="0">
              <span class="tw-share-icon">공유하기 아이콘</span>
              <span class="tw-share-txt">공유하기</span>
            </div>
          </div>
          <div class="tw-info-detail-commentbox">
            <p>공백</p>
            <ul class="tw-comment-list-wrap">
              <!-- 댓글 열린상태 예시 -->
              
              <cmmnt-comm-cmnt></cmmnt-comm-cmnt>

            </ul>
          </div>
          <send-input :cmmntCmnt="cmmntCmnt" @submit="regPostCmnt"></send-input>

          <!-- <div class="tw-info-detail-send">
            <div class="detail-send-img">
              <img src="/tripwith/assets/images/img-sample(5).png" alt="닉네임 프로필 이미지">
            </div>
            <div class="detail-send-input">
              <form>
                <input type="text" class="tw-input-default tw-input-comment" aria-label="댓글 달기">
              </form>
            </div>
            <button type="submit" class="detail-send-icon" aria-label="보내기 버튼">보내기</button>
          </div> -->


        </div>
      </div>
</template>

<script setup>
import { ref, reactive, onMounted, onBeforeMount, getCurrentInstance, defineProps, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { createLogger, useStore } from "vuex";


//글로벌 프로퍼티 설정 부분
import { useGlobalProperties } from '@/page/main/js/composables/useGlobalProperties'; 
const { $axios, $axiosJson } = useGlobalProperties();

import { useNavigation } from '@/page/main/js/useNavigation';
const { navigate, goBack } = useNavigation();

import { useComm } from '@/page/main/js/comm';
const { toggleCommLayer, toggleCommAlert, loginChk } = useComm();

const route = useRoute();
const store = useStore();

import cmmntCommCmnt from '@/page/main/views/cmmnt/comm/cmntLst.vue'
import sendInput from '@/page/main/views/cmmnt/comm/sendInput.vue'

const props = defineProps({
  postNum: {
    type: String,
    required: true
  }
});

const postNum = props.postNum;
const cmmnt = ref({});
const imgFiles = ref([]);

const sUser = computed(() => store.state.user.user);

const goGetCmmnt = async (postNum) => {
  let cmmnt;
    await $axios({
        method: 'GET',
        params: cmmnt,
        url: `/tripwith/post/user/${postNum}`,
    }).then((data) => {
        const resultObj = data.resultObj;
        if (resultObj) {
            cmmnt = resultObj;
        }
    }).catch((error) => {
        alert("데이터를 불러오는 도중 에러가 발생하였습니다. ");
    })
    return cmmnt;
};

onMounted( async () => {
  cmmnt.value = await goGetCmmnt(postNum);
  
  const fileLst = cmmnt.value.postFileModelLst;
  if (fileLst.length > 0) {
    fileLst.forEach(file => imgFiles.value.push(file));
  }
  cmmntCmntLst.value = await goGetCmntLst(cmmntCmnt.value);
  store.dispatch('cmmnt/setCmntLst', cmmntCmntLst.value);
})

//상단 더보기 아이콘 이벤트 관련
let bDtlShow = ref(false);

// 외부 클릭 이벤x트를 처리하는 함수
const onClickOutside = (event) => {
  bDtlShow.value = false;
}

const clickMoreIcon = () =>{
  event.stopPropagation();
  bDtlShow.value = !bDtlShow.value;
}

const goCmmntWrite = () =>{
    navigate({value:"cmmntWrite", params: {postNum:postNum}  } );
}

// 댓글
const goGetCmntLst = async (pCmmntCmnt) => {
  let tmpCmmntCmntLst;
    await $axios({
        method: 'GET',
        params: pCmmntCmnt,
        url: '/tripwith/cmnt/user',
    }).then((data) => {
        const resultObj = data.resultObj;
        if (resultObj) {
            tmpCmmntCmntLst = resultObj;
        }
    }).catch((error) => {
        alert("데이터를 불러오는 도중 에러가 발생하였습니다. ");
    })
    return tmpCmmntCmntLst;
};

const regPostCmnt = async (e) => {

    if(!loginChk()){
      //로그인하지 않은 경우 함수의 실행 중단
      return;
    };

    await $axiosJson({
        method: 'POST',
        data: e,
        url: '/tripwith/cmnt/user',
    }).then((data) => {
        const resultObj = data.resultObj;
        if (data.resultYn == 'Y') {

          //서버 통신을 최소화하려고 추가했으나 다중 사용자에 의해 등록될 경우 최신화가 되지 않아 리스트를 다시 불러오는 것으로 변경
          //store.dispatch('cmmnt/pushCmnt', resultObj);
          cmmntCmnt.value.cntn = '';

        }
    }).catch((error) => {
        alert("댓글 등록 중 에러가 발생하였습니다. ");
    })

    cmmntCmntLst.value = await goGetCmntLst(cmmntCmnt.value);
    store.dispatch('cmmnt/setCmntLst', cmmntCmntLst.value);
}

const cmmntCmnt = ref({postNum : postNum, cntn:''});
const cmmntCmntLst = ref([]);


const delCmmntConfirm = (pCmmnt) => {
  const onConfirm = () => {
    delCmmnt(cmmntCmnt.value); 
  };

  //confirm open
  toggleCommAlert("CMMNT-DEL", "CONFIRM", "CONFIRM", onConfirm);
}

const delCmmnt = async (pCmmnt) => {

  const onConfirm = () => {
    goBack(); 
    store.dispatch('alert/setAlert', {
        name: 'comm',
        show: false
    });
  };
    await $axios({
        method: 'DELETE',
        url: '/tripwith/post/user/' + pCmmnt.postNum,
    }).then((data) => {
        const resultObj = data.resultObj;
        if (data.resultYn == 'Y') {
          toggleCommAlert("CMMNT-DEL", "SUCCESS", "ALERT", onConfirm);
        }else{
          toggleCommAlert("CMMNT-DEL", "FAIL", "ALERT");
        }
    }).catch((error) => {
    toggleCommAlert("CMMNT-DEL", "FAIL", "ALERT");
    })
}


const togglePostLike = async (pCmmnt) => {

  if(!loginChk()){
    //로그인하지 않은 경우 함수의 실행 중단
    return;
  };

  if(!pCmmnt.likedByUser){
    await goRegPostLike(pCmmnt);
  }else{
    await goDelPostLike(pCmmnt);
  }
};

const goRegPostLike = async (pCmmnt) => {
    await $axios({
        method: 'POST',
        url: '/tripwith/post/' + pCmmnt.postNum + '/like',
    }).then((data) => {
        const resultYn = data.resultYn;
        if (resultYn == 'Y') {
          pCmmnt.likedByUser = true;
          pCmmnt.likeCount++;
        }
    }).catch((error) => {
        toggleCommAlert("COMM", "FAILED");
    })
}

const goDelPostLike = async (pCmmnt) => {
    await $axios({
        method: 'DELETE',
        url: '/tripwith/post/' + pCmmnt.postNum + '/like',
    }).then((data) => {
        const resultYn = data.resultYn;
        if (resultYn == 'Y') {
          pCmmnt.likedByUser = false;
          pCmmnt.likeCount--;
        }
    }).catch((error) => {
        toggleCommAlert("COMM", "FAILED");
    })
}


const togglePostDibs = async (pCmmnt) => {

  if(!loginChk()){
    //로그인하지 않은 경우 함수의 실행 중단
    return;
  };
  
  if(!pCmmnt.dibsedByUser){
    await goRegPostDibs(pCmmnt);
  }else{
    await goDelPostDibs(pCmmnt);
  }
};

const goRegPostDibs = async (pCmmnt) => {
    await $axios({
        method: 'POST',
        url: '/tripwith/post/' + pCmmnt.postNum + '/dibs',
    }).then((data) => {
        const resultYn = data.resultYn;
        if (resultYn == 'Y') {
          pCmmnt.dibsedByUser = true;
        }
    }).catch((error) => {
        toggleCommAlert("COMM", "FAILED");
    })
}

const goDelPostDibs = async (pCmmnt) => {
    await $axios({
        method: 'DELETE',
        url: '/tripwith/post/' + pCmmnt.postNum + '/dibs',
    }).then((data) => {
        const resultYn = data.resultYn;
        if (resultYn == 'Y') {
          pCmmnt.dibsedByUser = false;
        }
    }).catch((error) => {
        toggleCommAlert("COMM", "FAILED");
    })
}

function convertTimestampToFormattedDate(timestamp) {
    if(timestamp == undefined){
      return '';
    }

    // timestamp 값을 milliseconds로 변환
    const date = new Date(timestamp);

    // 원하는 형식으로 날짜를 포맷
    const options = { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit' 
    };
    
    // 로케일을 'ko-KR'로 설정하여 한국 형식으로 포맷
    const formattedDate = new Intl.DateTimeFormat('ko-KR', options).format(date);

    // 'yyyy. MM. dd' 포맷으로 반환
    return formattedDate.replace(/\. /g, '.');
}
</script>
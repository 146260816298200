var TRIPINFO = {
    SRCH : {
        CTGRY : {
            storeName : "tripinfo",
            stateName : "srch",
            funcName : "setTripinfoFromModal",
            valueName : "tripinfoCtgryCode",
            langPath : 'user.main.tripinfo.TI-CTGRY.',
            lst : ['000','001', '002', '003', '004', '005', '006', '007']
        }
    },
    FILTER : {
        CTGRY :{
            storeName : "tripinfo",
            stateName : "filter",
            funcName : "setTripinfoFromModal",
            valueName : "tripinfoCtgryCode",
            langPath : 'user.main.tripinfo.TI-CTGRY.',
            lst : ['000','001', '002', '003', '004', '005', '006', '007']
        }
    }
}

export default TRIPINFO;
<template>
    <div class="tw-wrap h-none">
        <router-view></router-view>
        <user-footer v-if="showFooter" ></user-footer>
        <sppiner v-if="showSppiner" ></sppiner>
        <comm-alert></comm-alert>
    </div>
</template>

<script setup>
import { computed, getCurrentInstance, onMounted, ref } from 'vue';
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import userFooter from "@/page/main/comm/footer.vue";
import sppiner from "@/page/main/comm/sppiner.vue";
import modal from "@/page/main/comm/modal.vue";
import commAlert from "@/page/main/comm/commAlert.vue";

const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;

//vuex 설정
const store = useStore();
//const layerLst = computed(() => store.state.location.layerLst);

const route = useRoute();

const showFooter = computed(() => route.name !== 'tripinfoDtl' && route.name !== 'tripinfoCmnt' && !route.name.includes('Write')  && !route.name.includes('Dtl')   && !route.name.includes('Cmnt') );
const showSppiner = computed(() => store.state.comm.showSppiner);


const bLoginChk = computed(() => store.state.user.user.bLoginChk);
const authorization = localStorage.getItem("authorization");

//토큰 인증 정보가 있고 bLoginChk가 false라면 현재 토큰이 유효한지 확인한다.

onMounted( async () => {
  if(!bLoginChk.value && authorization){
    let curUser = await loginVer();
    
    if(curUser && Object.keys(curUser).length !== 0){
    //userModule 생성
      let user = {
            loginGbnCode : "NORMAL",
            lang : 'krn',
            bLoginChk : true,
            userNum : curUser.userNum,
            nick : curUser.nick,
            gndr : curUser.gndr,
            prflImgNum : curUser.prflFileNum,
            prflFileFullPath : curUser.prflFileFullPath,
            birthYear : curUser.birthYear,
            birthMonth : curUser.birthMonth,
            birthDay : curUser.birthDay,
      }
    store.dispatch('user/setUser', user);  
    }
  }
})

navigator.geolocation.getCurrentPosition(
  function(position) {
  store.dispatch('location/setLocation', position.coords)
  }, 
);

const loginVer = async () => {
  let tmpUser = {};
  await $axios({
      method: 'POST',
      data: '',
      url: '/user/chkToken',
  }).then((data) => {
      const resultObj = data.resultObj;
      if (resultObj) {
          tmpUser = resultObj;
      }
  }).catch((error) => {
      alert("에러 발생");
  })
  return tmpUser;  
}
</script>
import { createI18n } from 'vue-i18n';

// const messages = {
//   eng: {
//     message: {
//       hello: 'hello world'
//     }
//   },
//   krn: {
//     message: {
//       hello: '안녕 세계'
//     }
//   }
// };

const messages = loadLocaleMessages()

function loadLocaleMessages() {
  // json 파일을 읽어들이기 위한 function
  const requireLang = require.context('@/lang', true, /\.json$/);
  const messages = {};
  // json file read
  for (const file of requireLang.keys()) {
    if (file === './index.js') continue; // index.js 파일 제외
    const path = file.replace(/(\.\/|\.json$)/g, '').split('/');
    path.reduce((o, s, i) => {
      if (o[s]) return o[s];
      o[s] = i + 1 === path.length ? requireLang(file) : {};
      return o[s];
    }, messages);
  }
  return messages;
}

const i18n = createI18n({
  locale: 'krn', // 기본 언어
  fallbackLocale: 'eng', // 대체 언어
  legacy: false,
  messages
});

export default i18n;

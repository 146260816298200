export const clickOutside = {
  beforeMount(el, binding) {
    el.clickOutsideEvent = function(event) {
      // 요소 및 그 자식 요소가 이벤트 대상이 아닌 경우
      if (!(el == event.target || el.contains(event.target))) {
        binding.value(event);  // 바인딩된 값(함수) 실행
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
};
<template>

  <div class="tw-body-category search">
    <div class="swiper txtSwiper tw-swiper-key tw-swiper-del swiper-initialized swiper-horizontal swiper-backface-hidden">
<!-- :space-between="5"  -->
      <Swiper :slides-per-view="'auto'" :space-between="5" >
        <!-- <SwiperSlide>
          <div class="swiper-slide swiper-slide-active" :class="'tw-swiper-txt'" role="group" aria-label="1 / 4" tabindex="0" @click="toggleCommLayer('tripinfoCtgryCode')" >키워드</div>
        </SwiperSlide> -->
        <!-- <SwiperSlide>
          <div class="swiper-slide" :class="'tw-swiper-txt'"  role="group" aria-label="2 / 4" tabindex="0" @click="toggleCommLayer('tripinfoCtgryCode')" >지역</div>
        </SwiperSlide> -->
        <SwiperSlide>
          <div class="swiper-slide tw-swiper-txt" :class="sSrch.tripinfoCtgryCode != '000' ? 'swiper-slide-active' : ''"  role="group" aria-label="3 / 4" tabindex="0" @click="clickTripinfoCtgry" >{{ $t('user.main.TRIPINFO.TI-CTGRY.' + sSrch.tripinfoCtgryCode) }}</div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="swiper-slide  tw-swiper-txt"  role="group" aria-label="4 / 4" tabindex="0" >날짜</div>
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>


<script setup>
import { computed, getCurrentInstance, onMounted, ref } from 'vue';
import { useStore } from "vuex";

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css'; // 기본 Swiper 스타일
import 'swiper/css/pagination'; // Swiper Pagination 스타일
import 'swiper/css/navigation'; // Swiper Navigation 스타일

import TRIPINFO_CNST from '@/page/main/views/tripinfo/js/tripInfoCnst'

const swiperRef = ref(null);
const store = useStore();

const isModalOpen = computed(() => store.state.layer.comm.show);
const lst = computed(() => store.state.layer.comm.lst);
const comm = computed(() => store.state.layer.comm);

import { useComm } from '@/page/main/js/comm';
const { toggleCommLayer } = useComm();

const sSrch = computed(() => store.state.tripinfo.srch);

const clickTripinfoCtgry = () => {
  toggleCommLayer(TRIPINFO_CNST.SRCH.CTGRY);
}
//부모 컴포넌트에서 받은 값
const props = defineProps({
  bnrLst: Array,
})

</script>

<template>
      <div class="tw-top main">
        <div class="tw-top-logo">
          <img src="/tripwith/assets/images/TripWith-logo-w.svg" alt="트립위드 로고">
          <!-- <span class="tw-alarm-icon">알림아이콘</span> -->
        </div>
        <div class="tw-top-search">
          <div class="tw-search-wrap">
            <input type="text" class="tw-input-default tw-input-search" :placeholder="$t('user.main.SEARCH.PLACEHOLDER')">
            <span class="tw-search-icon">search아이콘</span>
          </div>
        </div>
      </div>
      <div class="tw-wrap-content home current">
        <div class="tw-body main">          
          <bnrSwiper :bnrLst="bnrLst" />

          <div class="tw-main-info step1">
            <div class="tw-info-align">
              <h2 class="tw-label-title">{{$t('user.main.TRIPINFO.TITLE') }}</h2>
              <!-- <div class="tw-select-wrap small main">
                <button type="button" class="tw-select-default" aria-label="날씨/시차/환율 선택 버튼">날씨</button>
                <ul class="optionList">
                  <li class="optionItem" tabindex="0">날씨</li>
                  <li class="optionItem" tabindex="0">시차</li>
                  <li class="optionItem" tabindex="0">환율</li>
                </ul>
              </div> -->
            </div>
            <div class="tw-main-conbox-wrap step1">
              <nav>
                <ul class="tw-main-conbox">
                  <li class="tw-main-con" @click="goTripinfo('001')">
                    <a>
                      <p class="tw-con-bus-icon">대중교통 아이콘</p>
                      <p class="tw-con-txt" v-html="$t('user.main.TRIPINFO.TI-CTGRY.001')" ></p>
                    </a>
                  </li>
                  <li class="tw-main-con" @click="goTripinfo('002')">
                    <a>
                      <p class="tw-con-food-icon">식음료 아이콘</p>
                      <p class="tw-con-txt" v-html="$t('user.main.TRIPINFO.TI-CTGRY.002')"></p>
                    </a>
                  </li>
                  <li class="tw-main-con" @click="goTripinfo('003')">
                    <a>
                      <p class="tw-con-sho-icon">쇼핑 아이콘</p>
                      <p class="tw-con-txt" v-html="$t('user.main.TRIPINFO.TI-CTGRY.003')"></p>
                    </a>
                  </li>
                </ul>
              </nav>
              <nav>
                <ul class="tw-main-conbox">
                  <li class="tw-main-con" @click="goTripinfo('004')">
                    <a>
                      <p class="tw-con-act-icon">액티비티 아이콘</p>
                      <p class="tw-con-txt" v-html="$t('user.main.TRIPINFO.TI-CTGRY.004')"></p>
                    </a>
                  </li>
                  <li class="tw-main-con" @click="goTripinfo('005')">
                    <a>
                      <p class="tw-con-pop-icon">문화/관광 아이콘</p>
                      <p class="tw-con-txt" v-html="$t('user.main.TRIPINFO.TI-CTGRY.005')"></p>
                    </a>
                  </li>
                  <li class="tw-main-con" @click="goTripinfo('000')">
                    <a>
                      <p class="tw-con-plus-icon">더보기 아이콘</p>
                      <p class="tw-con-txt">더보기</p>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

            <div class="tw-body-line-wrap m-0">
              <p>라인</p>
            </div>
<!-- 
            <div class="tw-main-commu">
              <h2 class="tw-label-title">트립 커뮤니티</h2>
              <div class="tw-main-tab">
                <ul class="main-tabs">
                  <li class="tab-link current" data-tab="m-tab-1" tabindex="0">인기순</li>
                  <li class="tab-link" data-tab="m-tab-2" tabindex="0">조회순</li>
                  <li class="tab-link" data-tab="m-tab-3" tabindex="0">최신순</li>
                  <li class="tab-link" data-tab="m-tab-4" tabindex="0">MY찜</li>
                </ul>
                <div id="m-tab-1" class="tab-content current">
                  <div class="tw-main-tab-list">
                    <nav>
                      <ul>
                        <li>
                          <div class="rank">01</div>
                          <div class="rank-img">
                            <img src="/tripwith/assets/images/img-sample(1).png" alt="랭킹 이미지">
                          </div>
                          <div class="rank-wrap">
                            <div class="rank-wrap-detail">
                              <a href="../2step/CT_CC_001.html" >
                                <p class="detail-tit">저렴하게 렌트카 예약하는 방법</p>
                                <p class="detail-body">저렴하게 렌트카 예약하는 방법 저렴하게 렌트카 예약하는 방법</p>
                              </a>
                              <div class="info-txt-bt left">
                                <div class="info-bt-like icon" tabindex="0">
                                  <span class="tw-like-icon">좋아요 아이콘</span>
                                  <span class="tw-like-txt">좋아요</span>
                                </div>
                                <div class="info-bt-comm icon" tabindex="0">
                                  <span class="tw-comm-icon">댓글 아이콘</span>
                                  <span class="tw-comm-txt">댓글달기</span>
                                </div>
                                <div class="info-bt-view icon" tabindex="0">
                                  <span class="tw-view-icon">조회수 아이콘</span>
                                  <span class="tw-view-txt">조회수</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="rank">02</div>
                          <div class="rank-img">
                            <img src="/tripwith/assets/images/img-sample(2).png" alt="랭킹 이미지">
                          </div>
                          <div class="rank-wrap">
                            <div class="rank-wrap-detail">
                              <a href="../2step/CT_CC_001.html" >
                                <p class="detail-tit">저렴하게 렌트카 예약하는 방법</p>
                                <p class="detail-body">저렴하게 렌트카 예약하는 방법 저렴하게 렌트카 예약하는 방법</p>
                              </a>
                              <div class="info-txt-bt left">
                                <div class="info-bt-like icon on" tabindex="0">
                                  <span class="tw-like-icon">좋아요 아이콘</span>
                                  <span class="tw-like-txt">1,234</span>
                                </div>
                                <div class="info-bt-comm icon on" tabindex="0">
                                  <span class="tw-comm-icon">댓글 아이콘</span>
                                  <span class="tw-comm-txt">1,234</span>
                                </div>
                                <div class="info-bt-view icon on" tabindex="0">
                                  <span class="tw-view-icon">조회수 아이콘</span>
                                  <span class="tw-view-txt">1,234</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="rank">03</div>
                          <div class="rank-img">
                            <img src="/tripwith/assets/images/img-sample(3).png" alt="랭킹 이미지">
                          </div>
                          <div class="rank-wrap">
                            <div class="rank-wrap-detail">
                              <a href="../2step/CT_CC_001.html" >
                                <p class="detail-tit">저렴하게 렌트카 예약하는 방법</p>
                                <p class="detail-body">저렴하게 렌트카 예약하는 방법 저렴하게 렌트카 예약하는 방법</p>
                              </a>
                              <div class="info-txt-bt left">
                                <div class="info-bt-like icon" tabindex="0">
                                  <span class="tw-like-icon">좋아요 아이콘</span>
                                  <span class="tw-like-txt">1,234</span>
                                </div>
                                <div class="info-bt-comm icon" tabindex="0">
                                  <span class="tw-comm-icon">댓글 아이콘</span>
                                  <span class="tw-comm-txt">1,234</span>
                                </div>
                                <div class="info-bt-view icon" tabindex="0">
                                  <span class="tw-view-icon">조회수 아이콘</span>
                                  <span class="tw-view-txt">1,234</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="rank">04</div>
                          <div class="rank-img">
                            <img src="/tripwith/assets/images/img-sample(4).png" alt="랭킹 이미지">
                          </div>
                          <div class="rank-wrap">
                            <div class="rank-wrap-detail">
                              <a href="../2step/CT_CC_001.html" >
                                <p class="detail-tit">저렴하게 렌트카 예약하는 방법</p>
                                <p class="detail-body">저렴하게 렌트카 예약하는 방법 저렴하게 렌트카 예약하는 방법</p>
                              </a>
                              <div class="info-txt-bt left">
                                <div class="info-bt-like icon" tabindex="0">
                                  <span class="tw-like-icon">좋아요 아이콘</span>
                                  <span class="tw-like-txt">1,234</span>
                                </div>
                                <div class="info-bt-comm icon" tabindex="0">
                                  <span class="tw-comm-icon">댓글 아이콘</span>
                                  <span class="tw-comm-txt">1,234</span>
                                </div>
                                <div class="info-bt-view icon" tabindex="0">
                                  <span class="tw-view-icon">조회수 아이콘</span>
                                  <span class="tw-view-txt">1,234</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="rank">05</div>
                          <div class="rank-img">
                            <img src="/tripwith/assets/images/img-sample(5).png" alt="랭킹 이미지">
                          </div>
                          <div class="rank-wrap">
                            <div class="rank-wrap-detail">
                              <a href="../2step/CT_CC_001.html" >
                                <p class="detail-tit">저렴하게 렌트카 예약하는 방법</p>
                                <p class="detail-body">저렴하게 렌트카 예약하는 방법 저렴하게 렌트카 예약하는 방법</p>
                              </a>
                              <div class="info-txt-bt left">
                                <div class="info-bt-like icon" tabindex="0">
                                  <span class="tw-like-icon">좋아요 아이콘</span>
                                  <span class="tw-like-txt">1,234</span>
                                </div>
                                <div class="info-bt-comm icon" tabindex="0">
                                  <span class="tw-comm-icon">댓글 아이콘</span>
                                  <span class="tw-comm-txt">1,234</span>
                                </div>
                                <div class="info-bt-view icon" tabindex="0">
                                  <span class="tw-view-icon">조회수 아이콘</span>
                                  <span class="tw-view-txt">1,234</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        
                      </ul>
                    </nav>
                    <div class="tw-add-btn" style="margin-top: 20px;">
                      <a href="../2step/CT_CC_001.html" class="tw-btn-default border icon black" aria-label="더 보러가기 버튼">
                        <span class="tw-icon-text">더 보러가기</span>
                        <span class="tw-more-icon">화살표 아이콘</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div id="m-tab-2" class="tab-content">
                  <div class="tw-main-tab-list">
                    <nav>
                      <ul>
                        <li>
                          <div class="rank">01</div>
                          <div class="rank-img">
                            <img src="/tripwith/assets/images/img-sample(1).png" alt="랭킹 이미지">
                          </div>
                          <div class="rank-wrap">
                            <div class="rank-wrap-detail">
                              <a href="../2step/CT_CC_001.html" >
                                <p class="detail-tit">저렴하게 렌트카 예약하는 방법</p>
                                <p class="detail-body">저렴하게 렌트카 예약하는 방법 저렴하게 렌트카 예약하는 방법</p>
                              </a>
                              <div class="info-txt-bt left">
                                <div class="info-bt-like icon" tabindex="0">
                                  <span class="tw-like-icon">좋아요 아이콘</span>
                                  <span class="tw-like-txt">좋아요</span>
                                </div>
                                <div class="info-bt-comm icon" tabindex="0">
                                  <span class="tw-comm-icon">댓글 아이콘</span>
                                  <span class="tw-comm-txt">댓글달기</span>
                                </div>
                                <div class="info-bt-view icon" tabindex="0">
                                  <span class="tw-view-icon">조회수 아이콘</span>
                                  <span class="tw-view-txt">조회수</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="rank">02</div>
                          <div class="rank-img">
                            <img src="/tripwith/assets/images/img-sample(2).png" alt="랭킹 이미지">
                          </div>
                          <div class="rank-wrap">
                            <div class="rank-wrap-detail">
                              <a href="../2step/CT_CC_001.html" >
                                <p class="detail-tit">저렴하게 렌트카 예약하는 방법</p>
                                <p class="detail-body">저렴하게 렌트카 예약하는 방법 저렴하게 렌트카 예약하는 방법</p>
                              </a>
                              <div class="info-txt-bt left">
                                <div class="info-bt-like icon on" tabindex="0">
                                  <span class="tw-like-icon">좋아요 아이콘</span>
                                  <span class="tw-like-txt">1,234</span>
                                </div>
                                <div class="info-bt-comm icon on" tabindex="0">
                                  <span class="tw-comm-icon">댓글 아이콘</span>
                                  <span class="tw-comm-txt">1,234</span>
                                </div>
                                <div class="info-bt-view icon on" tabindex="0">
                                  <span class="tw-view-icon">조회수 아이콘</span>
                                  <span class="tw-view-txt">1,234</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="rank">03</div>
                          <div class="rank-img">
                            <img src="/tripwith/assets/images/img-sample(3).png" alt="랭킹 이미지">
                          </div>
                          <div class="rank-wrap">
                            <div class="rank-wrap-detail">
                              <a href="../2step/CT_CC_001.html" >
                                <p class="detail-tit">저렴하게 렌트카 예약하는 방법</p>
                                <p class="detail-body">저렴하게 렌트카 예약하는 방법 저렴하게 렌트카 예약하는 방법</p>
                              </a>
                              <div class="info-txt-bt left">
                                <div class="info-bt-like icon" tabindex="0">
                                  <span class="tw-like-icon">좋아요 아이콘</span>
                                  <span class="tw-like-txt">1,234</span>
                                </div>
                                <div class="info-bt-comm icon" tabindex="0">
                                  <span class="tw-comm-icon">댓글 아이콘</span>
                                  <span class="tw-comm-txt">1,234</span>
                                </div>
                                <div class="info-bt-view icon" tabindex="0">
                                  <span class="tw-view-icon">조회수 아이콘</span>
                                  <span class="tw-view-txt">1,234</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="rank">04</div>
                          <div class="rank-img">
                            <img src="/tripwith/assets/images/img-sample(4).png" alt="랭킹 이미지">
                          </div>
                          <div class="rank-wrap">
                            <div class="rank-wrap-detail">
                              <a href="../2step/CT_CC_001.html" >
                                <p class="detail-tit">저렴하게 렌트카 예약하는 방법</p>
                                <p class="detail-body">저렴하게 렌트카 예약하는 방법 저렴하게 렌트카 예약하는 방법</p>
                              </a>
                              <div class="info-txt-bt left">
                                <div class="info-bt-like icon" tabindex="0">
                                  <span class="tw-like-icon">좋아요 아이콘</span>
                                  <span class="tw-like-txt">1,234</span>
                                </div>
                                <div class="info-bt-comm icon" tabindex="0">
                                  <span class="tw-comm-icon">댓글 아이콘</span>
                                  <span class="tw-comm-txt">1,234</span>
                                </div>
                                <div class="info-bt-view icon" tabindex="0">
                                  <span class="tw-view-icon">조회수 아이콘</span>
                                  <span class="tw-view-txt">1,234</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="rank">05</div>
                          <div class="rank-img">
                            <img src="/tripwith/assets/images/img-sample(5).png" alt="랭킹 이미지">
                          </div>
                          <div class="rank-wrap">
                            <div class="rank-wrap-detail">
                              <a href="../2step/CT_CC_001.html" >
                                <p class="detail-tit">저렴하게 렌트카 예약하는 방법</p>
                                <p class="detail-body">저렴하게 렌트카 예약하는 방법 저렴하게 렌트카 예약하는 방법</p>
                              </a>
                              <div class="info-txt-bt left">
                                <div class="info-bt-like icon" tabindex="0">
                                  <span class="tw-like-icon">좋아요 아이콘</span>
                                  <span class="tw-like-txt">1,234</span>
                                </div>
                                <div class="info-bt-comm icon" tabindex="0">
                                  <span class="tw-comm-icon">댓글 아이콘</span>
                                  <span class="tw-comm-txt">1,234</span>
                                </div>
                                <div class="info-bt-view icon" tabindex="0">
                                  <span class="tw-view-icon">조회수 아이콘</span>
                                  <span class="tw-view-txt">1,234</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        
                      </ul>
                    </nav>
                    <div class="tw-add-btn" style="margin-top: 20px;">
                      <a href="../2step/CT_CC_001.html" class="tw-btn-default border icon black" aria-label="더 보러가기 버튼">
                        <span class="tw-icon-text">더 보러가기</span>
                        <span class="tw-more-icon">화살표 아이콘</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div id="m-tab-3" class="tab-content">
                  <div class="tw-main-tab-list">
                    <nav>
                      <ul>
                        <li>
                          <div class="rank">01</div>
                          <div class="rank-img">
                            <img src="/tripwith/assets/images/img-sample(1).png" alt="랭킹 이미지">
                          </div>
                          <div class="rank-wrap">
                            <div class="rank-wrap-detail">
                              <a href="../2step/CT_CC_001.html" >
                                <p class="detail-tit">저렴하게 렌트카 예약하는 방법</p>
                                <p class="detail-body">저렴하게 렌트카 예약하는 방법 저렴하게 렌트카 예약하는 방법</p>
                              </a>
                              <div class="info-txt-bt left">
                                <div class="info-bt-like icon" tabindex="0">
                                  <span class="tw-like-icon">좋아요 아이콘</span>
                                  <span class="tw-like-txt">좋아요</span>
                                </div>
                                <div class="info-bt-comm icon" tabindex="0">
                                  <span class="tw-comm-icon">댓글 아이콘</span>
                                  <span class="tw-comm-txt">댓글달기</span>
                                </div>
                                <div class="info-bt-view icon" tabindex="0">
                                  <span class="tw-view-icon">조회수 아이콘</span>
                                  <span class="tw-view-txt">조회수</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="rank">02</div>
                          <div class="rank-img">
                            <img src="/tripwith/assets/images/img-sample(2).png" alt="랭킹 이미지">
                          </div>
                          <div class="rank-wrap">
                            <div class="rank-wrap-detail">
                              <a href="../2step/CT_CC_001.html" >
                                <p class="detail-tit">저렴하게 렌트카 예약하는 방법</p>
                                <p class="detail-body">저렴하게 렌트카 예약하는 방법 저렴하게 렌트카 예약하는 방법</p>
                              </a>
                              <div class="info-txt-bt left">
                                <div class="info-bt-like icon on" tabindex="0">
                                  <span class="tw-like-icon">좋아요 아이콘</span>
                                  <span class="tw-like-txt">1,234</span>
                                </div>
                                <div class="info-bt-comm icon on" tabindex="0">
                                  <span class="tw-comm-icon">댓글 아이콘</span>
                                  <span class="tw-comm-txt">1,234</span>
                                </div>
                                <div class="info-bt-view icon on" tabindex="0">
                                  <span class="tw-view-icon">조회수 아이콘</span>
                                  <span class="tw-view-txt">1,234</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="rank">03</div>
                          <div class="rank-img">
                            <img src="/tripwith/assets/images/img-sample(3).png" alt="랭킹 이미지">
                          </div>
                          <div class="rank-wrap">
                            <div class="rank-wrap-detail">
                              <a href="../2step/CT_CC_001.html" >
                                <p class="detail-tit">저렴하게 렌트카 예약하는 방법</p>
                                <p class="detail-body">저렴하게 렌트카 예약하는 방법 저렴하게 렌트카 예약하는 방법</p>
                              </a>
                              <div class="info-txt-bt left">
                                <div class="info-bt-like icon" tabindex="0">
                                  <span class="tw-like-icon">좋아요 아이콘</span>
                                  <span class="tw-like-txt">1,234</span>
                                </div>
                                <div class="info-bt-comm icon" tabindex="0">
                                  <span class="tw-comm-icon">댓글 아이콘</span>
                                  <span class="tw-comm-txt">1,234</span>
                                </div>
                                <div class="info-bt-view icon" tabindex="0">
                                  <span class="tw-view-icon">조회수 아이콘</span>
                                  <span class="tw-view-txt">1,234</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="rank">04</div>
                          <div class="rank-img">
                            <img src="/tripwith/assets/images/img-sample(4).png" alt="랭킹 이미지">
                          </div>
                          <div class="rank-wrap">
                            <div class="rank-wrap-detail">
                              <a href="../2step/CT_CC_001.html" >
                                <p class="detail-tit">저렴하게 렌트카 예약하는 방법</p>
                                <p class="detail-body">저렴하게 렌트카 예약하는 방법 저렴하게 렌트카 예약하는 방법</p>
                              </a>
                              <div class="info-txt-bt left">
                                <div class="info-bt-like icon" tabindex="0">
                                  <span class="tw-like-icon">좋아요 아이콘</span>
                                  <span class="tw-like-txt">1,234</span>
                                </div>
                                <div class="info-bt-comm icon" tabindex="0">
                                  <span class="tw-comm-icon">댓글 아이콘</span>
                                  <span class="tw-comm-txt">1,234</span>
                                </div>
                                <div class="info-bt-view icon" tabindex="0">
                                  <span class="tw-view-icon">조회수 아이콘</span>
                                  <span class="tw-view-txt">1,234</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="rank">05</div>
                          <div class="rank-img">
                            <img src="/tripwith/assets/images/img-sample(5).png" alt="랭킹 이미지">
                          </div>
                          <div class="rank-wrap">
                            <div class="rank-wrap-detail">
                              <a href="../2step/CT_CC_001.html" >
                                <p class="detail-tit">저렴하게 렌트카 예약하는 방법</p>
                                <p class="detail-body">저렴하게 렌트카 예약하는 방법 저렴하게 렌트카 예약하는 방법</p>
                              </a>
                              <div class="info-txt-bt left">
                                <div class="info-bt-like icon" tabindex="0">
                                  <span class="tw-like-icon">좋아요 아이콘</span>
                                  <span class="tw-like-txt">1,234</span>
                                </div>
                                <div class="info-bt-comm icon" tabindex="0">
                                  <span class="tw-comm-icon">댓글 아이콘</span>
                                  <span class="tw-comm-txt">1,234</span>
                                </div>
                                <div class="info-bt-view icon" tabindex="0">
                                  <span class="tw-view-icon">조회수 아이콘</span>
                                  <span class="tw-view-txt">1,234</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        
                      </ul>
                    </nav>
                    <div class="tw-add-btn" style="margin-top: 20px;">
                      <a href="../2step/CT_CC_001.html" class="tw-btn-default border icon black" aria-label="더 보러가기 버튼">
                        <span class="tw-icon-text">더 보러가기</span>
                        <span class="tw-more-icon">화살표 아이콘</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div id="m-tab-4" class="tab-content">
                  <div class="tw-main-tab-list">
                    <nav>
                      <ul>
                        <li>
                          <div class="rank">01</div>
                          <div class="rank-img">
                            <img src="/tripwith/assets/images/img-sample(1).png" alt="랭킹 이미지">
                          </div>
                          <div class="rank-wrap">
                            <div class="rank-wrap-detail">
                              <a href="../2step/CT_CC_001.html" >
                                <p class="detail-tit">저렴하게 렌트카 예약하는 방법</p>
                                <p class="detail-body">저렴하게 렌트카 예약하는 방법 저렴하게 렌트카 예약하는 방법</p>
                              </a>
                              <div class="info-txt-bt left">
                                <div class="info-bt-like icon" tabindex="0">
                                  <span class="tw-like-icon">좋아요 아이콘</span>
                                  <span class="tw-like-txt">좋아요</span>
                                </div>
                                <div class="info-bt-comm icon" tabindex="0">
                                  <span class="tw-comm-icon">댓글 아이콘</span>
                                  <span class="tw-comm-txt">댓글달기</span>
                                </div>
                                <div class="info-bt-view icon" tabindex="0">
                                  <span class="tw-view-icon">조회수 아이콘</span>
                                  <span class="tw-view-txt">조회수</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="rank">02</div>
                          <div class="rank-img">
                            <img src="/tripwith/assets/images/img-sample(2).png" alt="랭킹 이미지">
                          </div>
                          <div class="rank-wrap">
                            <div class="rank-wrap-detail">
                              <a href="../2step/CT_CC_001.html" >
                                <p class="detail-tit">저렴하게 렌트카 예약하는 방법</p>
                                <p class="detail-body">저렴하게 렌트카 예약하는 방법 저렴하게 렌트카 예약하는 방법</p>
                              </a>
                              <div class="info-txt-bt left">
                                <div class="info-bt-like icon on" tabindex="0">
                                  <span class="tw-like-icon">좋아요 아이콘</span>
                                  <span class="tw-like-txt">1,234</span>
                                </div>
                                <div class="info-bt-comm icon on" tabindex="0">
                                  <span class="tw-comm-icon">댓글 아이콘</span>
                                  <span class="tw-comm-txt">1,234</span>
                                </div>
                                <div class="info-bt-view icon on" tabindex="0">
                                  <span class="tw-view-icon">조회수 아이콘</span>
                                  <span class="tw-view-txt">1,234</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="rank">03</div>
                          <div class="rank-img">
                            <img src="/tripwith/assets/images/img-sample(3).png" alt="랭킹 이미지">
                          </div>
                          <div class="rank-wrap">
                            <div class="rank-wrap-detail">
                              <a href="../2step/CT_CC_001.html" >
                                <p class="detail-tit">저렴하게 렌트카 예약하는 방법</p>
                                <p class="detail-body">저렴하게 렌트카 예약하는 방법 저렴하게 렌트카 예약하는 방법</p>
                              </a>
                              <div class="info-txt-bt left">
                                <div class="info-bt-like icon" tabindex="0">
                                  <span class="tw-like-icon">좋아요 아이콘</span>
                                  <span class="tw-like-txt">1,234</span>
                                </div>
                                <div class="info-bt-comm icon" tabindex="0">
                                  <span class="tw-comm-icon">댓글 아이콘</span>
                                  <span class="tw-comm-txt">1,234</span>
                                </div>
                                <div class="info-bt-view icon" tabindex="0">
                                  <span class="tw-view-icon">조회수 아이콘</span>
                                  <span class="tw-view-txt">1,234</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="rank">04</div>
                          <div class="rank-img">
                            <img src="/tripwith/assets/images/img-sample(4).png" alt="랭킹 이미지">
                          </div>
                          <div class="rank-wrap">
                            <div class="rank-wrap-detail">
                              <a href="../2step/CT_CC_001.html" >
                                <p class="detail-tit">저렴하게 렌트카 예약하는 방법</p>
                                <p class="detail-body">저렴하게 렌트카 예약하는 방법 저렴하게 렌트카 예약하는 방법</p>
                              </a>
                              <div class="info-txt-bt left">
                                <div class="info-bt-like icon" tabindex="0">
                                  <span class="tw-like-icon">좋아요 아이콘</span>
                                  <span class="tw-like-txt">1,234</span>
                                </div>
                                <div class="info-bt-comm icon" tabindex="0">
                                  <span class="tw-comm-icon">댓글 아이콘</span>
                                  <span class="tw-comm-txt">1,234</span>
                                </div>
                                <div class="info-bt-view icon" tabindex="0">
                                  <span class="tw-view-icon">조회수 아이콘</span>
                                  <span class="tw-view-txt">1,234</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="rank">05</div>
                          <div class="rank-img">
                            <img src="/tripwith/assets/images/img-sample(5).png" alt="랭킹 이미지">
                          </div>
                          <div class="rank-wrap">
                            <div class="rank-wrap-detail">
                              <a href="../2step/CT_CC_001.html" >
                                <p class="detail-tit">저렴하게 렌트카 예약하는 방법</p>
                                <p class="detail-body">저렴하게 렌트카 예약하는 방법 저렴하게 렌트카 예약하는 방법</p>
                              </a>
                              <div class="info-txt-bt left">
                                <div class="info-bt-like icon" tabindex="0">
                                  <span class="tw-like-icon">좋아요 아이콘</span>
                                  <span class="tw-like-txt">1,234</span>
                                </div>
                                <div class="info-bt-comm icon" tabindex="0">
                                  <span class="tw-comm-icon">댓글 아이콘</span>
                                  <span class="tw-comm-txt">1,234</span>
                                </div>
                                <div class="info-bt-view icon" tabindex="0">
                                  <span class="tw-view-icon">조회수 아이콘</span>
                                  <span class="tw-view-txt">1,234</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        
                      </ul>
                    </nav>
                    <div class="tw-add-btn" style="margin-top: 20px;">
                      <a href="../2step/CT_CC_001.html" class="tw-btn-default border icon black" aria-label="더 보러가기 버튼">
                        <span class="tw-icon-text">더 보러가기</span>
                        <span class="tw-more-icon">화살표 아이콘</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      <div id="f-tab-2" class="tw-wrap-content info">
        두번째
      </div>
      <div id="f-tab-3" class="tw-wrap-content commu">
        세번째
      </div>
      <div id="f-tab-4" class="tw-wrap-content my">
        네번째
      </div>

</template>

<script setup>

import { ref, onMounted, onBeforeMount, getCurrentInstance, computed, reactive } from 'vue'
import { useStore } from "vuex";
import { createI18n } from 'vue-i18n'
import { useI18n } from 'vue-i18n';

import bnrSwiper from "@/page/main/comm/bnrSwiper.vue"

import { useNavigation } from '@/page/main/js/useNavigation';
const { navigate } = useNavigation();

const bnr = ref({});
const bnrLst = ref([]);


//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const $axiosJson = proxy.$axiosJson;

//vuex 설정
const store = useStore();

const getBnrLst = async (bnr) => {
    let tmpBnrLst;
    await $axios({
        method: 'POST',
        data: bnr,
        url: '/bnr/lstview',
    }).then((data) => {
        const resultObj = data.resultObj;
        if (resultObj) {
            tmpBnrLst = resultObj;
        }
    }).catch((error) => {
        alert("데이터를 불러오는 도중 에러가 발생하였습니다. ");
    })
    return tmpBnrLst;
};

const goTripinfo = (tripinfoCtgryCode) => {
  const data = {
        stateName : 'srch',
        key : 'tripinfoCtgryCode',
        value:tripinfoCtgryCode}
  store.dispatch("tripinfo/setTripinfoFromModal", data);
  navigate({value:'tripinfoSrch'});
}

onMounted( async () => {
  bnr.value.bnrCode = "23";
  bnrLst.value = await getBnrLst(bnr.value);
})


</script>
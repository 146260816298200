<template>
        <header class="tw-top search">
          <a class="tw-top-icon" @click="goBack()">뒤로가기 아이콘</a>
          <div class="tw-top-search">
            <form class="tw-search-wrap" role="search">
              <input type="search" class="tw-input-default tw-input-search" placeholder="원하는 언어를 검색하세요" aria-label="가이드 통합검색">
              <span class="tw-search-icon" tabindex="0">검색 아이콘</span>
            </form>
          </div>

          <div class="tw-pop-wrap search" role="dialog" aria-modal="true">
            <ul class="tw-pop-search-box">
              <li class="default-list history" tabindex="0">한국어</li>
              <li class="default-list history" tabindex="0">영어</li>
            </ul>
          </div>
        </header>

        <div class="tw-body p-0">
          <div class="tw-setting-box full">
            <h2 class="tw-label-default bold f16 bt-p">언어 설정</h2>
            <ul class="tw-setting-list-box">
              <li class="default-list" tabindex="0" @click="clickLang('krn')" :class=" $i18n.locale == 'krn' ? 'active' : null" >한국어 <span>active icon</span> </li>
              <li class="default-list" tabindex="0" @click="clickLang('eng')" :class=" $i18n.locale == 'eng' ? 'active' : null" > 영어 <span>active icon</span> </li>
            </ul>
          </div>
        </div>
</template>


<script setup>

import { ref, onMounted, onBeforeMount, getCurrentInstance, computed } from 'vue'
import { useRouter } from 'vue-router';
import { createLogger, useStore } from "vuex";
import { useI18n } from 'vue-i18n';
//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();
const $axios = proxy.$axios;
const router = useRouter()
const store = useStore();

const { locale } = useI18n();

import { useNavigation } from '@/page/main/js/useNavigation';
const { navigate, goBack } = useNavigation();


const clickLang = (lang) => {
  locale.value = lang;
}

onMounted(() => {
})
</script>
<template>
  <template v-if="!isReply">
    <div class="tw-comment-list-left">
      <div class="tw-comment-img">
        <img :src="computedProfileImageSrc" alt="닉네임 프로필 이미지">
      </div>
      <div class="tw-comment-txt">
        <div class="comm-txt-name">{{ tripinfoCmnt.nick }}
          <!-- <span class="id-badge">
            <img src="/tripwith/assets/images/img-level-total-1.svg" alt="뱃지 이미지">
          </span> -->
          <span class="comm-txt-my" v-if="tripinfoCmnt.regUserNum == sUserNum">Me</span>
        </div>
        <template v-if="selModTripinfoCmntNum == tripinfoCmnt.tripinfoCmntNum">
        <div class="tw-info-detail-send">
            <div class="detail-send-input">
                <input type="text" class="tw-input-default tw-input-comment" v-model="tripinfoCmnt.cntn" @keydown.enter="regTripinfoCmntDeb(tripinfoCmnt.tripinfoCmntNum)" :ref="setInputRef(tripinfoCmnt.tripinfoCmntNum)">
            </div>
            <div class="detail-send-icon on" tabindex="0" @click="() => regTripinfoCmntDeb(tripinfoCmnt.tripinfoCmntNum)">보내기</div>
        </div>
        </template>
        <template v-else>
          <div class="comm-txt-body">{{ tripinfoCmnt.cntn }}</div>
        </template>
        <div class="comm-txt-bt">
          <div class="info-bt-like icon" tabindex="0">
            <span class="tw-like-icon">좋아요 아이콘</span>
            <span class="tw-like-txt">좋아요</span>
          </div>
          <div class="info-bt-comm icon" tabindex="0" @click="goTripinfoCmnt(tripinfoCmnt.tripinfoNum, tripinfoCmnt.tripinfoCmntNum)" >
            <span class="tw-comm-icon">댓글 아이콘</span>
            <span class="tw-comm-txt">댓글달기</span>
          </div>
        </div>
      </div>
      <div  v-if="tripinfoCmnt.regUserNum == sUserNum  && tripinfoCmnt.delYn == 'N'" class="tw-comment-icon" tabindex="0" @click="toggleModal(tripinfoCmnt.tripinfoCmntNum, $event)" aria-haspopup="true">댓글 더보기 아이콘</div>

      <div class="tw-pop-mini" role="dialog" aria-modal="true" v-click-outside="onClickOutside" v-if="isActiveModal">
        <ul>
          <li @click="modCmnt(tripinfoCmnt.tripinfoCmntNum)"><a>수정하기</a></li>
          <li @click="delTripinfoCmntConfirm(tripinfoCmnt)" tabindex="0"><a>삭제하기</a></li>
        </ul>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="tw-comment-img">
      <img :src="computedProfileImageSrc" alt="닉네임 프로필 이미지">
    </div>
    <div class="tw-comment-txt">
      <div class="comm-txt-name">{{ tripinfoCmnt.nick }}
        <span class="comm-txt-my" v-if="tripinfoCmnt.regUserNum == sUserNum">Me</span>
      </div>
      <template v-if="selModTripinfoCmntNum == tripinfoCmnt.tripinfoCmntNum">
      <div class="tw-info-detail-send">
          <div class="detail-send-input">
              <input type="text" class="tw-input-default tw-input-comment" v-model="tripinfoCmnt.cntn" @keydown.enter="regTripinfoCmntDeb(tripinfoCmnt.tripinfoCmntNum)" :ref="setInputRef(tripinfoCmnt.tripinfoCmntNum)">
          </div>
          <div class="detail-send-icon on" tabindex="0" @click="() => regTripinfoCmntDeb(tripinfoCmnt.tripinfoCmntNum)">보내기</div>
      </div>
      </template>
      <template v-else>
        <div class="comm-txt-body">{{ tripinfoCmnt.cntn }}</div>
      </template>
      <div class="comm-txt-bt">
        <div class="info-bt-like icon" tabindex="0">
          <span class="tw-like-icon">좋아요 아이콘</span>
          <span class="tw-like-txt">좋아요</span>
        </div>
      </div>
    </div>
    <div  v-if="tripinfoCmnt.regUserNum == sUserNum  && tripinfoCmnt.delYn == 'N'" class="tw-comment-icon" tabindex="0" @click="toggleModal(tripinfoCmnt.tripinfoCmntNum, $event)" aria-haspopup="true">댓글 더보기 아이콘</div>

    <div class="tw-pop-mini" role="dialog" aria-modal="true" v-click-outside="onClickOutside" v-if="isActiveModal">
      <ul>
        <li @click="modCmnt(tripinfoCmnt.tripinfoCmntNum)"><a>수정하기</a></li>
        <li @click="delTripinfoCmntConfirm(tripinfoCmnt)" tabindex="0"><a>삭제하기</a></li>
      </ul>
    </div>
  </template>
</template>
<script setup>
import { ref, onMounted, onBeforeMount, onBeforeUnmount, computed, nextTick } from 'vue'
import { createLogger, useStore } from "vuex";

//글로벌 프로퍼티 설정 부분
import { useGlobalProperties } from '@/page/main/js/composables/useGlobalProperties'; 
const { $axios, $axiosJson } = useGlobalProperties();

import { useComm } from '@/page/main/js/comm';
const { toggleCommAlert, loginChk } = useComm();

import { useNavigation } from '@/page/main/js/useNavigation';
const { navigate } = useNavigation();

// Props 정의
const props = defineProps({
  tripinfoCmnt: Object,
  sUserNum: String,
  isReply: Boolean,
});

const computedProfileImageSrc = computed(() => props.tripinfoCmnt.prflFileFullPath || '/tripwith/assets/images/img-profile.png');
const selModTripinfoCmntNum = ref(0);

const inputRefs = ref({});

const store = useStore();

const goTripinfoCmnt = (pTripinfoNum, pTripinfoCmntNum) => {
    if(!loginChk()){
      return;
    }

    store.dispatch('tripinfo/clearCmntLst', null);
    //여기서 리스트를 초기화하여 cmnt 접근 시 리스트가 이상하게 보이는 부분을 보완하도록 해야 함. 
    navigate({value:"tripinfoCmnt", querys: {tripinfoNum:pTripinfoNum, tripinfoCmntNum:pTripinfoCmntNum} } );
}

const regTripinfoCmnt = async (pTripinfoCmntNum) => {

    const selInputRef = inputRefs.value[pTripinfoCmntNum];
    if(selInputRef.value == ''){
      return false;
    }

    const cmntData = {
      cntn : selInputRef.value,
      tripinfoCmntNum : selModTripinfoCmntNum.value
    }

    await $axiosJson({
        method: 'POST',
        data: cmntData,
        url: '/tripinfo/cmnt/reg',
    }).then((data) => {
        const resultObj = data.resultObj;
        if (data.resultYn == 'Y') {
          selModTripinfoCmntNum.value = null;
        }
    }).catch((error) => {
        alert("댓글 등록 중 에러가 발생하였습니다. ");
    })
}

const regTripinfoCmntDeb = _.throttle((pTripinfoCmntNum) => {
    regTripinfoCmnt(pTripinfoCmntNum);
}, 3000);

const modCmnt = (pTripinfoCmntNum) => {
  store.dispatch('tripinfo/clearActiveCmntNum');
  selModTripinfoCmntNum.value = pTripinfoCmntNum;

  nextTick(() => {
    if (inputRefs.value[pTripinfoCmntNum]) {
      inputRefs.value[pTripinfoCmntNum].focus();
    }
  });
}

const delTripinfoCmntConfirm = (pTripinfoCmnt) => {
  const onConfirm = () => {
    delTripinfoCmnt(pTripinfoCmnt); // 클로저를 통해 pTripinfoCmntNum 접근
  };

  //confirm open
  toggleCommAlert("TRIPINFO-CMNT-DEL", "CONFIRM", "CONFIRM", onConfirm);
}

const delTripinfoCmnt = async (pTripinfoCmnt) => {
    const tripinfoCmntView = computed(() => store.getters['tripinfo/selCmnt'](pTripinfoCmnt));

    const cmntData = {
      tripinfoNum : tripinfoCmntView.value.tripinfoNum,
      tripinfoCmntNum : tripinfoCmntView.value.tripinfoCmntNum,
      cntn : tripinfoCmntView.value.cntn,
    }

    if(tripinfoCmntView.value.upprTripinfoCmntNum){
      cmntData.upprTripinfoCmntModel = {tripinfoCmntNum : tripinfoCmntView.value.upprTripinfoCmntNum};
    }

    await $axiosJson({
        method: 'POST',
        data: cmntData,
        url: '/tripinfo/cmnt/del',
    }).then((data) => {
        const resultObj = data.resultObj;
        if (data.resultYn == 'Y') {
          store.dispatch('tripinfo/delCmnt', resultObj);
          toggleCommAlert("TRIPINFO-CMNT-DEL", "SUCCESS", "ALERT");
        }else{
          toggleCommAlert("TRIPINFO-CMNT-DEL", "FAIL", "ALERT");
        }
    }).catch((error) => {
    toggleCommAlert("TRIPINFO-CMNT-DEL", "FAIL", "ALERT");
    })
}

function setInputRef(tripinfoCmntNum) {
  return (el) => {
    if (el) {
      inputRefs.value[tripinfoCmntNum] = el;
    }
  };
}

const toggleModal = (tripinfoCmntNum, event) => {
    // 이벤트 전파 방지
    event.stopPropagation();
    store.dispatch('tripinfo/setActiveCmntNum', tripinfoCmntNum);
}

const isActiveModal = computed(() => {
  return store.state.tripinfo.cmnt.activeCmntNum == props.tripinfoCmnt.tripinfoCmntNum;
});

// 외부 클릭 이벤트를 처리하는 함수
const onClickOutside = (event) => {
  store.dispatch('tripinfo/clearActiveCmntNum');
}
</script>
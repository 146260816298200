<template>
        <header class="tw-top search">
          <a class="tw-top-icon" @click="goBack()">뒤로가기 아이콘</a>
          <div class="tw-top-search">
            <div class="tw-search-wrap" role="search">
              <input 
                type="search" 
                class="tw-input-default tw-input-search" 
                placeholder="어떤 가이드가 필요하세요?" 
                aria-label="가이드 통합검색" 
                v-model="sSrch.keyword"
                @keyup.enter="goStartSrch(cmmnt.value)"
              >
              <span 
                class="tw-search-icon" 
                tabindex="0"
                @click="goStartSrch(cmmnt.value)"
                @keyup.enter="goStartSrch(cmmnt.value)"
              >
                검색 아이콘
              </span>
            </div>
          </div>
        </header>

        <div class="tw-body p-0">
          
          <filter-swiper />

          <div class="tw-align-box">
            <label class="tw-label-body">총<span>{{ cmmnt.totalCnt }}</span>건</label>
            <div class="tw-select-wrap small none" ref="selOrder" @click="clickOrderLst" >
              <button type="button" class="tw-select-default" aria-label="정렬 선택 버튼">{{ $t('user.main.COMM.COMM-ORDER.' + sSrch.orderCode) }} </button>
              <ul class="optionList">
                <li class="optionItem" tabindex="0" @click="clickOrder('001')" >{{ $t('user.main.COMM.COMM-ORDER.001') }}</li>
                <li class="optionItem" tabindex="0" @click="clickOrder('002')">{{ $t('user.main.COMM.COMM-ORDER.002') }}</li>
                <li class="optionItem" tabindex="0" @click="clickOrder('003')">{{ $t('user.main.COMM.COMM-ORDER.003') }}</li>
              </ul>
            </div>
          </div>

          <div class="tw-main-tab-list search">
            <nav>
              <ul>
                <li v-for="(cmmnt, index) in cmmntLst" :key="index" @click="goCmntDtl(cmmnt.postNum)">
                  <div class="rank">{{ index + 1 }}</div>
                  <div class="rank-img">
                    <a v-if="cmmnt.thmbFullPath" ><img :src="cmmnt.thmbFullPath" alt="랭킹 이미지"></a>
                    <a v-else><img src="/tripwith/assets/images/img-sample(1).png" alt="랭킹 이미지"></a>
                  </div>
                  <div class="rank-wrap">
                    <div class="rank-wrap-detail">
                      <a>
                        <p class="detail-tit">{{ cmmnt.title }}</p>
                        <p class="detail-body">{{ cmmnt.cntn }}</p>
                      </a>
                      <div class="info-txt-bt left">
                            <div class="info-bt-like icon" :class="cmmnt.likeCnt > 0 ? 'on' : ''" tabindex="0">
                              <span class="tw-like-icon">좋아요 아이콘</span>
                              <span class="tw-like-txt">{{ cmmnt.likeCnt > 0 ? cmmnt.likeCnt : '좋아요'}}</span>
                            </div>
                        <div class="info-bt-comm icon" :class="cmmnt.cmntCnt > 0 ? 'on' : ''" tabindex="0">
                          <span class="tw-comm-icon">댓글 아이콘</span>
                          <span class="tw-comm-txt">{{ cmmnt.cmntCnt > 0 ? cmmnt.cmntCnt : '댓글달기'}}</span>
                        </div>
                        <div class="info-bt-view icon" :class="cmmnt.viewCnt > 0 ? 'on' : ''" tabindex="0">
                          <span class="tw-view-icon">조회수 아이콘</span>
                          <span class="tw-view-txt">{{ cmmnt.viewCnt > 0 ? cmmnt.viewCnt : '조회수'}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <modal></modal>
</template>

<script setup>
import { ref, reactive, onMounted, onBeforeMount, getCurrentInstance, computed, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { createLogger, useStore } from "vuex";


//글로벌 프로퍼티 설정 부분
import { useGlobalProperties } from '@/page/main/js/composables/useGlobalProperties'; 
const { $axios, $axiosJson } = useGlobalProperties();

import { useNavigation } from '@/page/main/js/useNavigation';
const { navigate, goBack } = useNavigation();

import modal from "@/page/main/comm/modal.vue";
import filterSwiper from "@/page/main/views/cmmnt/comm/filterSwiper.vue"

const pageObj = reactive({totalCnt : 0 //최대 리스트 개수
                    ,totalPage : 100 // 최대 페이지 개수 
                    ,pageNum : 0 //현재 페이지 번호(실제 페이지 넘버 -1)
                    ,pageSize : 30 //보여줄 페이징의 최대 개수
                    ,pageBlockNum : 8 //한 페이지 내에 보여줄 리스트 개수
                    })


const store = useStore();


const cmmnt = ref({});
const cmmntLst = ref([]);

const sSrch = computed(() => store.state.cmmnt.srch);

const selOrder = ref(null);


const goStartSrch = async () => {

  //최종 데이터에서는 sSrch에 있는 데이터를 pCmmnt에 입력한다.
  cmmnt.value.postCtgryCode = sSrch.value.postCtgryCode;
  cmmnt.value.keyword = sSrch.value.keyword;
  cmmnt.value.orderCode = sSrch.value.orderCode;

  cmmntLst.value = await goGetLstCmmnt(cmmnt.value);
}

const goGetLstCmmnt = async (pCmmnt) => {
  let cmmntLst;
    await $axios({
        method: 'GET',
        params: pCmmnt,
        url: '/tripwith/post/user',
    }).then((data) => {
        const resultObj = data.resultObj.content;
        if (resultObj) {
            cmmntLst = resultObj;
            cmmnt.value.totalCnt = data.resultObj.totalElements;
        }
    }).catch((error) => {
        alert("데이터를 불러오는 도중 에러가 발생하였습니다. ");
    })
    return cmmntLst;
};


const goCmntDtl = (postNum) => {
  navigate({value:"cmmntDtl", params: {postNum:postNum} } );
}

onMounted( async () => {
  cmmnt.value.pageNum = pageObj.pageNum;
  cmmnt.value.pageSize = pageObj.pageSize;
  cmmnt.value.boardNum = '3';
  cmmnt.value.postCtgryCode = sSrch.value.postCtgryCode;
  cmmntLst.value = await goGetLstCmmnt(cmmnt.value);
})


const clickOrderLst = () => {
  selOrder.value.classList;
  if(selOrder.value.classList.contains('active')){
    selOrder.value.classList.remove('active');
  }else{
    selOrder.value.classList.add('active');
  }
};

const clickOrder = async (pOrder) => {
  const setSrch = {
    stateName : 'srch',
    key : 'orderCode',
    value : pOrder,
  }
  store.dispatch('cmmnt/setCmmntFromModal', setSrch);
  cmmnt.value.orderCtgryCode = sSrch.value.orderCode;
  goGetLstCmmnt(cmmnt.value);

}


watch(
  () => sSrch.value.postCtgryCode,
  (newCtgryCode, oldCtgryCode) => {
    goStartSrch(cmmnt.value);
  }
)
</script>
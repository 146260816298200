<template>


    <div class="col-12 col-md-8 col-xl-9 align-top" id="webcam-container">
      <div id="video-container">
        <video id="webcam" autoplay muted playsinline></video>
      </div>  
    </div>

    <div class="tw-wrap">
      <div class="tw-top main">
        <div class="tw-top-logo">
          <img src="/tripwith/assets/images/TripWith-logo.svg" alt="트립위드 로고">
          <span class="tw-alarm-icon">알림아이콘</span>
        </div>
        <div class="tw-top-search">
          <div class="tw-search-wrap">
            <input type="text" class="tw-input-default tw-input-search" :placeholder="t('user.main.search.placeholder')">
            <span class="tw-search-icon">search아이콘</span>
          </div>
        </div>
      </div>
      <div id="f-tab-1" class="tw-wrap-content home current">
        <div class="tw-body main">
          <div class="tw-main-banner">
            <div class="banner-corver">
              <p class="banner-txt-trip">어서오세요 트립 위드입니다.</p>
              <p class="banner-txt-bt">한국 여행을 위한 교통, 관광명소 등 <br> 다양한 가이드를 소개합니다.</p>
            </div>
            <img src="/tripwith/assets/images/img-banner.png" alt="배너 사진">
          </div>
          <div class="tw-main-info">
            <label class="tw-label-title">{{t('user.main.tripinfo.title') }}</label>
            <div class="tw-main-conbox-wrap">
              <ul class="tw-main-conbox">
                <li class="tw-main-con">
                  <p class="tw-con-bus-icon">아이콘</p>
                  <p class="tw-con-txt">대중교통</p>
                </li>
                <li class="tw-main-con">
                  <p class="tw-con-food-icon">아이콘</p>
                  <p class="tw-con-txt">식음료</p>
                </li>
                <li class="tw-main-con">
                  <p class="tw-con-sho-icon">아이콘</p>
                  <p class="tw-con-txt">식음료</p>
                </li>
              </ul>
              <ul class="tw-main-conbox">
                <li class="tw-main-con">
                  <p class="tw-con-act-icon">아이콘</p>
                  <p class="tw-con-txt more">액티비티</p>
                </li>
                <li class="tw-main-con">
                  <p class="tw-con-pop-icon">아이콘</p>
                  <p class="tw-con-txt more">문화/관광</p>
                </li>
                <li class="tw-main-con">
                  <p class="tw-con-plus-icon">아이콘</p>
                  <p class="tw-con-txt del">{{t('user.main.tripinfo.more') }}</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="tw-main-commu">
            <label class="tw-label-title">{{t('user.main.trip_commu.title') }}</label>
            <div class="tw-main-tab">
              <ul class="main-tabs">
                <li class="tab-link current" data-tab="m-tab-1">{{t('user.main.trip_commu.hot_order') }}</li>
                <li class="tab-link" data-tab="m-tab-2">{{t('user.main.trip_commu.cnt_order') }}</li>
                <li class="tab-link" data-tab="m-tab-3">{{t('user.main.trip_commu.latest_order') }}</li>
                <li class="tab-link" data-tab="m-tab-4">{{t('user.main.trip_commu.my_order') }}</li>
              </ul>
              <div id="m-tab-1" class="tab-content current">
                <div class="tw-main-tab-list">
                  <ul>
                    <li>
                      <div class="rank">01</div>
                      <div class="rank-img">
                        <img src="/tripwith/assets/images/img-banner.png" alt="랭킹 이미지">
                      </div>
                      <div class="rank-wrap">
                        <div class="rank-wrap-detail">
                          <p class="detail-tit">저렴하게 렌트카 예약하는 방법</p>
                          <p class="detail-body">저렴하게 렌트카 예약하는 방법 저렴하게 렌트카 예약하는 방법</p>
                          <p class="detail-sub">
                            <span>조회수<span class="sub-num">1,234</span></span>
                            <span>댓글수<span class="sub-num">1,234</span></span>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="rank">02</div>
                      <div class="rank-img">
                        <img src="/tripwith/assets/images/img-banner.png" alt="랭킹 이미지">
                      </div>
                      <div class="rank-wrap">
                        <div class="rank-wrap-detail">
                          <p class="detail-tit">저렴하게 렌트카 예약하는 방법</p>
                          <p class="detail-body">저렴하게 렌트카 예약하는 방법 저렴하게 렌트카 예약하는 방법</p>
                          <p class="detail-sub">
                            <span>조회수<span class="sub-num">1,234</span></span>
                            <span>댓글수<span class="sub-num">1,234</span></span>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="rank">03</div>
                      <div class="rank-img">
                        <img src="/tripwith/assets/images/img-banner.png" alt="랭킹 이미지">
                      </div>
                      <div class="rank-wrap">
                        <div class="rank-wrap-detail">
                          <p class="detail-tit">저렴하게 렌트카 예약하는 방법</p>
                          <p class="detail-body">저렴하게 렌트카 예약하는 방법 저렴하게 렌트카 예약하는 방법</p>
                          <p class="detail-sub">
                            <span>조회수<span class="sub-num">1,234</span></span>
                            <span>댓글수<span class="sub-num">1,234</span></span>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="rank">04</div>
                      <div class="rank-img">
                        <img src="/tripwith/assets/images/img-banner.png" alt="랭킹 이미지">
                      </div>
                      <div class="rank-wrap">
                        <div class="rank-wrap-detail">
                          <p class="detail-tit">저렴하게 렌트카 예약하는 방법</p>
                          <p class="detail-body">저렴하게 렌트카 예약하는 방법 저렴하게 렌트카 예약하는 방법</p>
                          <p class="detail-sub">
                            <span>조회수<span class="sub-num">1,234</span></span>
                            <span>댓글수<span class="sub-num">1,234</span></span>
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <button type="button" class="tw-btn-default icon icon-plus">
                    <span class="tw-icon-text">더 보러가기</span>
                    <span class="tw-more-icon">이메일 아이콘</span>
                  </button>
                </div>
              </div>
              <div id="m-tab-2" class="tab-content">
                <div class="tw-main-tab-list">
                  <ul>
                    <li>
                      <div class="rank">01</div>
                      <div class="rank-img">
                        <img src="/tripwith/assets/images/img-banner.png" alt="랭킹 이미지">
                      </div>
                      <div class="rank-wrap">
                        <div class="rank-wrap-detail">
                          <p class="detail-tit">올림픽공원역 JYP 방문 후기</p>
                          <p class="detail-body">올림픽공원역 JYP 방문 후기 올림픽공원역 JYP 방문 후기</p>
                          <p class="detail-sub">
                            <span>조회수<span class="sub-num">1,234</span></span>
                            <span>댓글수<span class="sub-num">1,234</span></span>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="rank">02</div>
                      <div class="rank-img">
                        <img src="/tripwith/assets/images/img-banner.png" alt="랭킹 이미지">
                      </div>
                      <div class="rank-wrap">
                        <div class="rank-wrap-detail">
                          <p class="detail-tit">올림픽공원역 JYP 방문 후기</p>
                          <p class="detail-body">올림픽공원역 JYP 방문 후기 올림픽공원역 JYP 방문 후기</p>
                          <p class="detail-sub">
                            <span>조회수<span class="sub-num">1,234</span></span>
                            <span>댓글수<span class="sub-num">1,234</span></span>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="rank">03</div>
                      <div class="rank-img">
                        <img src="/tripwith/assets/images/img-banner.png" alt="랭킹 이미지">
                      </div>
                      <div class="rank-wrap">
                        <div class="rank-wrap-detail">
                          <p class="detail-tit">올림픽공원역 JYP 방문 후기</p>
                          <p class="detail-body">올림픽공원역 JYP 방문 후기 올림픽공원역 JYP 방문 후기</p>
                          <p class="detail-sub">
                            <span>조회수<span class="sub-num">1,234</span></span>
                            <span>댓글수<span class="sub-num">1,234</span></span>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="rank">04</div>
                      <div class="rank-img">
                        <img src="/tripwith/assets/images/img-banner.png" alt="랭킹 이미지">
                      </div>
                      <div class="rank-wrap">
                        <div class="rank-wrap-detail">
                          <p class="detail-tit">올림픽공원역 JYP 방문 후기</p>
                          <p class="detail-body">올림픽공원역 JYP 방문 후기 올림픽공원역 JYP 방문 후기</p>
                          <p class="detail-sub">
                            <span>조회수<span class="sub-num">1,234</span></span>
                            <span>댓글수<span class="sub-num">1,234</span></span>
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <button type="button" class="tw-btn-default icon icon-plus">
                    <span class="tw-icon-text">더 보러가기</span>
                    <span class="tw-more-icon">이메일 아이콘</span>
                  </button>
                </div>
              </div>
              <div id="m-tab-3" class="tab-content">
                <div class="tw-main-tab-list">
                  <ul>
                    <li>
                      <div class="rank">01</div>
                      <div class="rank-img">
                        <img src="/tripwith/assets/images/img-banner.png" alt="랭킹 이미지">
                      </div>
                      <div class="rank-wrap">
                        <div class="rank-wrap-detail">
                          <p class="detail-tit">롯데월드 N번째 방문자가 쓰는 롯데월드 100% 즐기기</p>
                          <p class="detail-body">롯데월드 N번째 방문자가 쓰는 롯데월드 100% 즐기기</p>
                          <p class="detail-sub">
                            <span>조회수<span class="sub-num">1,234</span></span>
                            <span>댓글수<span class="sub-num">1,234</span></span>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="rank">02</div>
                      <div class="rank-img">
                        <img src="/tripwith/assets/images/img-banner.png" alt="랭킹 이미지">
                      </div>
                      <div class="rank-wrap">
                        <div class="rank-wrap-detail">
                          <p class="detail-tit">롯데월드 N번째 방문자가 쓰는 롯데월드 100% 즐기기</p>
                          <p class="detail-body">롯데월드 N번째 방문자가 쓰는 롯데월드 100% 즐기기</p>
                          <p class="detail-sub">
                            <span>조회수<span class="sub-num">1,234</span></span>
                            <span>댓글수<span class="sub-num">1,234</span></span>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="rank">03</div>
                      <div class="rank-img">
                        <img src="/tripwith/assets/images/img-banner.png" alt="랭킹 이미지">
                      </div>
                      <div class="rank-wrap">
                        <div class="rank-wrap-detail">
                          <p class="detail-tit">롯데월드 N번째 방문자가 쓰는 롯데월드 100% 즐기기</p>
                          <p class="detail-body">롯데월드 N번째 방문자가 쓰는 롯데월드 100% 즐기기</p>
                          <p class="detail-sub">
                            <span>조회수<span class="sub-num">1,234</span></span>
                            <span>댓글수<span class="sub-num">1,234</span></span>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="rank">04</div>
                      <div class="rank-img">
                        <img src="/tripwith/assets/images/img-banner.png" alt="랭킹 이미지">
                      </div>
                      <div class="rank-wrap">
                        <div class="rank-wrap-detail">
                          <p class="detail-tit">롯데월드 N번째 방문자가 쓰는 롯데월드 100% 즐기기</p>
                          <p class="detail-body">롯데월드 N번째 방문자가 쓰는 롯데월드 100% 즐기기</p>
                          <p class="detail-sub">
                            <span>조회수<span class="sub-num">1,234</span></span>
                            <span>댓글수<span class="sub-num">1,234</span></span>
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <button type="button" class="tw-btn-default icon icon-plus">
                    <span class="tw-icon-text">더 보러가기</span>
                    <span class="tw-more-icon">이메일 아이콘</span>
                  </button>
                </div>
              </div>
              <div id="m-tab-4" class="tab-content">
                <div class="tw-main-tab-list">
                  <ul>
                    <li>
                      <div class="rank">01</div>
                      <div class="rank-img">
                        <img src="/tripwith/assets/images/img-banner.png" alt="랭킹 이미지">
                      </div>
                      <div class="rank-wrap">
                        <div class="rank-wrap-detail">
                          <p class="detail-tit">가이드북에 없는 힙지로 방문하기</p>
                          <p class="detail-body">가이드북에 없는 힙지로 방문하기 가이드북에 없는 힙지로 방문하기</p>
                          <p class="detail-sub">
                            <span>조회수<span class="sub-num">1,234</span></span>
                            <span>댓글수<span class="sub-num">1,234</span></span>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="rank">02</div>
                      <div class="rank-img">
                        <img src="/tripwith/assets/images/img-banner.png" alt="랭킹 이미지">
                      </div>
                      <div class="rank-wrap">
                        <div class="rank-wrap-detail">
                          <p class="detail-tit">가이드북에 없는 힙지로 방문하기</p>
                          <p class="detail-body">가이드북에 없는 힙지로 방문하기 가이드북에 없는 힙지로 방문하기</p>
                          <p class="detail-sub">
                            <span>조회수<span class="sub-num">1,234</span></span>
                            <span>댓글수<span class="sub-num">1,234</span></span>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="rank">03</div>
                      <div class="rank-img">
                        <img src="/tripwith/assets/images/img-banner.png" alt="랭킹 이미지">
                      </div>
                      <div class="rank-wrap">
                        <div class="rank-wrap-detail">
                          <p class="detail-tit">가이드북에 없는 힙지로 방문하기</p>
                          <p class="detail-body">가이드북에 없는 힙지로 방문하기 가이드북에 없는 힙지로 방문하기</p>
                          <p class="detail-sub">
                            <span>조회수<span class="sub-num">1,234</span></span>
                            <span>댓글수<span class="sub-num">1,234</span></span>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="rank">04</div>
                      <div class="rank-img">
                        <img src="/tripwith/assets/images/img-banner.png" alt="랭킹 이미지">
                      </div>
                      <div class="rank-wrap">
                        <div class="rank-wrap-detail">
                          <p class="detail-tit">가이드북에 없는 힙지로 방문하기</p>
                          <p class="detail-body">가이드북에 없는 힙지로 방문하기 가이드북에 없는 힙지로 방문하기</p>
                          <p class="detail-sub">
                            <span>조회수<span class="sub-num">1,234</span></span>
                            <span>댓글수<span class="sub-num">1,234</span></span>
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <button type="button" class="tw-btn-default icon icon-plus">
                    <span class="tw-icon-text">더 보러가기</span>
                    <span class="tw-more-icon">이메일 아이콘</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="f-tab-2" class="tw-wrap-content info">
        두번째
      </div>
      <div id="f-tab-3" class="tw-wrap-content commu">
        세번째
      </div>
      <div id="f-tab-4" class="tw-wrap-content my">
        네번째
      </div>
      <div class="tw-footer">
        <ul class="footer-tabs">
          <li class="tab-link current" data-tab="f-tab-1">
            <p class="tw-footer-home-icon icon">홈 아이콘</p>
            <p class="tw-footer-txt">Home</p>
          </li>
          <li class="tab-link" data-tab="f-tab-2">
            <p class="tw-footer-info-icon icon">트립인포 아이콘</p>
            <p class="tw-footer-txt">Trip Info</p>
          </li>
          <li class="tab-link" data-tab="f-tab-3">
            <p class="tw-footer-commu-icon icon">커뮤니티 아이콘</p>
            <p class="tw-footer-txt">Community</p>
          </li>
          <li class="tab-link" data-tab="f-tab-4">
            <p class="tw-footer-my-icon icon">마이페이지 아이콘</p>
            <p class="tw-footer-txt">MyPage</p>
          </li>
        </ul>
      </div>

    </div>
</template>

<script setup>

import { ref, onMounted, onBeforeMount, getCurrentInstance, computed, reactive } from 'vue'
import { useStore } from "vuex";
import { createI18n } from 'vue-i18n'
import { useI18n } from 'vue-i18n';


// implements nodejs wrappers for HTMLCanvasElement, HTMLImageElement, ImageData
//import * as canvas from 'canvas';
import * as faceapi from 'face-api.js';



//글로벌 프로퍼티 설정 부분
const { proxy } = getCurrentInstance();

const messages = loadLocaleMessages()

function loadLocaleMessages() {
  // json 파일을 읽어들이기 위한 function
  const requireLang = require.context('@/lang', true, /\.json$/);
  const messages = {};
  // json file read
  for (const file of requireLang.keys()) {
    if (file === './index.js') continue; // index.js 파일 제외
    const path = file.replace(/(\.\/|\.json$)/g, '').split('/');
    path.reduce((o, s, i) => {
      if (o[s]) return o[s];
      o[s] = i + 1 === path.length ? requireLang(file) : {};
      return o[s];
    }, messages);
  }
  return messages;
}


console.log(messages);

//vuex 설정
const store = useStore();

//메시지
//$t로 공통 언어에 접근하고 그냥 t로 각 페이지별 언어에 접근한다. 

const { t } = useI18n({messages});


  const MODEL_URL = '/models' //model directory
  const modelPath = '/models';
  let currentStream;
  let displaySize;
  let canvas;
  let webcamElement;

  let faceDetection;

onMounted( async () => {

  webcamElement = document.getElementById('webcam');

  if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
  navigator.mediaDevices.getUserMedia({ video: true })
    .then(stream => {
      const video = document.getElementById('webcam');
      video.srcObject = stream;
    })
    .catch(error => {
      console.error("카메라 접근 오류:", error);
    });
} else {
  alert("이 브라우저는 카메라 기능을 지원하지 않습니다.");
}

  // await faceapi.loadSsdMobilenetv1Model(MODEL_URL) 
  // await faceapi.loadFaceLandmarkModel(MODEL_URL) // model to detect face landmark
  // await faceapi.loadFaceRecognitionModel(MODEL_URL) //model to Recognise Face
  // await faceapi.loadFaceExpressionModel(MODEL_URL) //model to detect face expression


  canvas = faceapi.createCanvasFromMedia(webcamElement)
  document.getElementById('webcam-container').append(canvas)

  displaySize = { width:300, height: 300}
  faceapi.matchDimensions(canvas, displaySize)


    Promise.all([
      faceapi.nets.tinyFaceDetector.load(modelPath),
      faceapi.nets.faceLandmark68TinyNet.load(modelPath),
      faceapi.nets.faceExpressionNet.load(modelPath),
      faceapi.nets.ageGenderNet.load(modelPath)
    ]).then(function(){
      startDetection();
    })
})



function startDetection(){
  faceDetection = setInterval(async () => {
    const detections = await faceapi.detectAllFaces(webcamElement, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks(true).withFaceExpressions().withAgeAndGender()
    const resizedDetections = faceapi.resizeResults(detections, displaySize)
    canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height)
    if($("#box-switch").is(":checked")){
      faceapi.draw.drawDetections(canvas, resizedDetections)
    }
    if($("#landmarks-switch").is(":checked")){
      faceapi.draw.drawFaceLandmarks(canvas, resizedDetections)
    }
    if($("#expression-switch").is(":checked")){
      faceapi.draw.drawFaceExpressions(canvas, resizedDetections)
    }
    if($("#age-gender-switch").is(":checked")){
      resizedDetections.forEach(result => {
        const { age, gender, genderProbability } = result
        new faceapi.draw.DrawTextField(
          [
            `${faceapi.round(age, 0)} years`,
            `${gender} (${faceapi.round(genderProbability)})`
          ],
          result.detection.box.bottomRight
        ).draw(canvas)
      })
    }
    
    if(!$(".loading").hasClass('d-none')){
      $(".loading").addClass('d-none')
    }
  }, 300)
}
</script>